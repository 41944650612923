import React, {Component} from "react";
import axiosClient from "../../repo/backend";

export default class IndustrySelector extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            industriesDropDown: []
        };
        this.onChange.bind(this)
    }

    componentDidMount() {
        this.getIndustries();
    }

    getIndustries() {
        axiosClient.get("/industries").then(resIndustries => {

            let options = [];

            options.push(
                <option key={"All Industries"} value={"All Industries"}>All Industries</option>
            );

            resIndustries.data.forEach(element => {
                options.push(
                    <option key={element.industry_name} value={element.industry_name}>{element.industry_name}</option>
                );
            });

            this.setState({
                industriesDropDown: options
            });
        });
    }

    onChange(e) {
        this.props.setIndustryName(e.target.value);
    }

    render() {
        return (
            <select
                className={this.props.className}
                onChange={this.onChange.bind(this)}
                value={this.props.industryName}
            >
                {this.state.industriesDropDown}
            </select>
        );
    }
}

import React, {Component} from "react";
import {Link} from "react-router-dom";
import VOCPieChart from "./piecharts/VOCPieChart";
import "./PieCharts.css"
// import TableauTemplate from "./piecharts/TableauTemplate";

import piechartsBanner from "../img/banner/piecharts.png"
import SourcesFootnotes from "./SourcesFootnotes";

import pdf from "../pdf/Voices of CO Narrative_7-2022.pdf"
import VOCBarChart from "./piecharts/VOCBarChart";

import infograph from "../img/infograph/voc_infograph.png"
import ZoomAdjuster from "../ZoomAdjuster";
import ReactGA from "react-ga4";
import red_play_button_clipart from "../img/clipart/redPlayButton.png";
import VimeoDemoVideo from "../video/demo/VimeoDemoVideo";
import TableauTemplate from "./piecharts/TableauTemplate";

export default class PieCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDemoVideo: false,
            showInstructions: false
        }
        this.handleInstructionsClick.bind(this);

    }

    componentDidMount() {
        document.querySelector(".page").scrollIntoView();
        ReactGA.send({hitType: "pageview", page: "/piecharts"});
        console.log("pageview sent");
    }

    handleInstructionsClick() {
        this.setState({
            showInstructions: !this.state.showInstructions
        });
    }

    render() {

        const Instructions = () => {
            return (
                <div className={"alignLeft"}>
                    {this.state.showInstructions &&
                        <div className={"instructions inlineBlock"}>
                            <div className={"inlineBlock"}>
                                <b>Instructions for Voices of Colorado Page:</b>
                                <ol>
                                    <li>This page was added to highlight job seeker preferences and concerns,
                                        supporting the NAICS and SOC classification data stories from the other pages.
                                    </li>
                                    <li>The first infographic highlights findings from the IndigoPathway job seeker
                                        survey.
                                    </li>
                                    <li>The bar charts beneath the infographic compare job seeker preferences to
                                        the percentage of jobs available to meet those preferences.
                                    </li>
                                    <li>The embedded data site at the bottom of the page displays results from the
                                        Colorado
                                        Pulse Survey highlighting concerns from state residents.
                                    </li>
                                    <li>Use the Command Key (Mac) or Control Key (PC) + and - keys to scale the page to
                                        your screen size. Site not designed for small screens.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    }
                </div>
            );
        }

        const NavigationButtons = (props) => {
            return (
                <div className={"navigation"}>
                    <div className={"inlineBlock"}>
                        <button className={"nav" + (this.state.showInstructions ? " navOpen" : "")}
                                onClick={this.handleInstructionsClick.bind(this)}>Instructions
                        </button>
                        <div className={"inlineBlock demoVideoDesktop"}>
                            <button className={"nav" + (this.state.showDemoVideo ? " navOpen" : "")}
                                    onClick={() => props.show()}>
                                <div>
                                    <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                                    <div style={{
                                        verticalAlign: "middle",
                                        display: "inline-block",
                                        paddingLeft: "10px"
                                    }}>
                                        <img src={"https://www.pngkey.com/png/detail/3-33600_youtube-icon-block-play-button-icon-red.png"}
                                             alt={""}
                                             style={{width: "30px"}}/>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className={"inlineBlock"}
                         style={{width: "calc(100% - 1280px)"}}></div>
                    <div style={{width: "750px", display: "inline-block"}}>

                        <button className={"nav"}>
                            <Link to="/graphs"
                                  className={"linkDecoration"}>Industry Demand</Link>
                        </button>
                        <button className={"nav"}>
                            <Link to="/tables"
                                  className={"linkDecoration"}>Occupation Demand</Link>
                        </button>
                        <button className={"nav navSelected"}
                                disabled={true}>
                            <Link to="/piecharts"
                                  className={"linkDecoration"}>Voices of Colorado</Link>
                        </button>
                    </div>
                </div>
            );
        }

        ZoomAdjuster();

        return (
            <div className={"page"}>

                <img src={piechartsBanner}
                     className={"title"}
                     alt={""}/>
                <NavigationButtons show={() => {
                    this.setState({
                        showDemoVideo: true
                    })
                }}/>
                <Instructions/>
                <VimeoDemoVideo showDemoVideo={this.state.showDemoVideo}
                                show={() => {
                                    this.setState({
                                        showDemoVideo: true
                                    })
                                }}
                                hide={() => {
                                    this.setState({
                                        showDemoVideo: false
                                    })
                                }}/>

                <div className={"alignCenter demoVideoMobile"}>
                    <button className={"nav"}
                            style={{width: "40%", backgroundColor: "white", borderRadius: "20px"}}
                            onClick={() => {
                                this.setState({showDemoVideo: true})
                            }}>
                        <div style={{zoom: "150%"}}>
                            <div className={"smallGap"}></div>
                            <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                            <div style={{verticalAlign: "middle", display: "inline-block", paddingLeft: "10px"}}>
                                <img src={red_play_button_clipart}
                                     alt={""}
                                     style={{width: "30px"}}/>
                            </div>
                        </div>
                    </button>
                </div>

                <h3 style={{textAlign: "center"}}>
                    Want to take the survey yourself? Visit <a href={"https://ColoradoCareerAdvising.com"} target={"_blank"} rel={"noreferrer"}>ColoradoCareerAdvising.com</a>
                </h3>

                <div className={"alignCenter"}>
                    <img src={infograph}
                         style={{minWidth: "1024px", maxWidth: "1300px", width: "calc(100% - 620px)"}}
                         alt={"Infograph"}/>
                </div>

                <div className={"pieChartBody"}>
                    <div className={"rowOfPieCharts"}>
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work from Home Preference"}*/}
                        {/*        centerCaption={""}*/}
                        {/*        url={"/pcHomePathview"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work from Home - Jobs Available"}*/}
                        {/*        centerCaption={""}*/}
                        {/*        url={"/pcHomePi"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"pie35"}>
                            <VOCBarChart title={"Work from Home Comparison"}
                                         centerCaption={""}
                                         url={"/pcHomeCombined"}/>
                        </div>
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work on Computer Preference"}*/}
                        {/*        centerCaption={""}*/}
                        {/*        url={"/pcComputer"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"pie35"}>
                            <VOCBarChart title={"Work on Computer Comparison"}
                                         centerCaption={""}
                                         url={"/pcComputerCombined"}/>
                        </div>
                    </div>
                    <div className={"rowOfPieCharts"}>
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work Outside Preference"}*/}
                        {/*        url={"/pcOutside"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"pie35"}>
                            <VOCBarChart title={"Work Outside Comparison"}
                                         centerCaption={""}
                                         url={"/pcOutsideCombined"}/>
                        </div>
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work With Hands Preference"}*/}
                        {/*        url={"/pcHands"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"pie35"}>
                            <VOCBarChart title={"Work With Hands Comparison"}
                                         centerCaption={""}
                                         url={"/pcHandsCombined"}/>
                        </div>
                    </div>
                    <div className={"rowOfPieCharts"}>
                        {/*<div className={"pie4"}>*/}
                        {/*    <VOCPieChart*/}
                        {/*        title={"Work With Kids Preference"}*/}
                        {/*        url={"/pcKids"}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"pie4"}>
                            <VOCPieChart title={"Feels Happy"}
                                         centerCaption={""}
                                         url={"/pcHappy"}/>
                        </div>
                        {/*    <div className={"pie"}>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"rowOfPieCharts"}>*/}
                        {/*    <div className={"pie"}>*/}
                        {/*    </div>*/}
                        <div className={"pie4"}>
                            <VOCPieChart title={"New job search distance"}
                                         centerCaption={""}
                                         url={"/pcAreaRange"}/>
                        </div>
                        <div className={"pie4"}>
                            <VOCPieChart title={"Job Status"}
                                         centerCaption={""}
                                         url={"/pcJobStatus"}/>
                        </div>
                    </div>
                    <div className={"rowOfPieCharts"}>
                        <div className={"tableauTemplate"}>
                            <TableauTemplate/>
                        </div>
                    </div>

                    <div className={"largeGap"}>&nbsp;</div>

                    <div className={"footNotesShape inlineBlock"}>
                        <SourcesFootnotes pdf={pdf}>
                            <li>The “preferences” section of the page shows data from questions asked on the
                                IndigoPathway
                                workforce development survey, taken by 43,756 respondents between February 2021 and
                                April
                                2022.
                            </li>
                            <li>“Jobs available” data is generated from the Occupations Data page on this site.</li>
                            <li>
                                <a href={"https://www.copulsepoll.org/results"}
                                   rel={"noreferrer"}
                                   target={"_blank"}>
                                    Colorado Health Foundation's
                                </a>
                                &nbsp;2023 Pulse Survey data was embedded in this page because it
                                highlights behavioral data and personal concerns facing many Coloradans right now and
                                helps
                                paint the overall workforce picture.
                            </li>
                        </SourcesFootnotes>
                    </div>
                </div>

                <div className={"largeGap"}>&nbsp;</div>

                <div id={"bottomOfThePage"}>&nbsp;</div>

            </div>
        );
    }

}

import React, { useEffect } from 'react';

const TableauTemplate = () => {
    useEffect(() => {
        const divElement = document.getElementById('viz1711065460758');
        const vizElement = divElement.getElementsByTagName('object')[0];
        vizElement.style.width = '1366px';
        vizElement.style.height = '795px';

        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
        vizElement.parentNode.insertBefore(scriptElement, vizElement);

        return () => {
            if (scriptElement.parentNode) {
                scriptElement.parentNode.removeChild(scriptElement);
            }
        };
    }, []);

    return (
            <div className='tableauPlaceholder' id='viz1711065460758' style={{ position: 'relative' }}>
                <noscript>
                    <a href='https://www.copulsepoll.org'>
                        <img
                                alt='Index'
                                src='https://public.tableau.com/static/images/H6/H6Y8R9BRG/1_rss.png'
                                style={{ border: 'none' }}
                        />
                    </a>
                </noscript>
                <object className='tableauViz' style={{ display: 'none' }}>
                    <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                    <param name='embed_code_version' value='3' />
                    <param name='path' value='shared/H6Y8R9BRG' />
                    <param name='toolbar' value='yes' />
                    <param name='static_image' value='https://public.tableau.com/static/images/H6/H6Y8R9BRG/1.png' />
                    <param name='animate_transition' value='yes' />
                    <param name='display_static_image' value='yes' />
                    <param name='display_spinner' value='yes' />
                    <param name='display_overlay' value='yes' />
                    <param name='display_count' value='yes' />
                    <param name='tabs' value='no' />
                    <param name='language' value='en-US' />
                </object>
            </div>
    );
};

export default TableauTemplate;

import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Bar} from 'react-chartjs-2';

import axiosClient from '../../repo/backend'

ChartJS.register(...registerables);

export default class WagesBar1YearAllIndustries extends Component {
    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: [],
            year: 2022
        };

        this.handleChange.bind(this);

        this.lbl = [];
        this.lblAbbrev = [];
        this.values1 = [];
        this.values2 = [];
    }

    handleChange(event) {
        let year = event.target.value;
        this.setState({
            year: year
        })
    }

    componentDidMount() {
        this.getChartData();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevState.year !== this.state.year) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/wages/year/" + this.state.year;
        axiosClient.get(url).then(resGapByCounty => {

            this.lbl = [];
            this.lblAbbrev = [];
            this.values1 = [];
            this.values2 = []

            resGapByCounty.data.forEach(element => {
                this.lbl.push(element["industry_name"]);
                this.values1.push(element["wages_increase_pct"]);
                try {
                    this.values2.push('$' + Intl.NumberFormat('en-US').format(parseInt(element['wages_per_worker'])));
                } catch {
                    this.values2.push("N/A");
                }
            });

            this.lbl.forEach(element => {
                this.lblAbbrev.push(element.substring(0, 25));
            });

            this.setState({
                barData: {
                    labels: this.lblAbbrev,
                    datasets: [
                        {
                            label: 'Wages Increase %',
                            data: this.values1,
                            backgroundColor: '#87C13F',
                        }
                    ],
                },
                barOptions: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ' '
                                        + context.formattedValue
                                        + ' - '
                                        + 'Average wages per worker '
                                        + this.values2[context.dataIndex];
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lblAbbrev,
                datasets: []
            },
        });
    }

    render() {
        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    <div className={"inlineBlock"}>
                        Wages percentage increase across industries between 2001 and
                    </div>
                    <div className={"inlineBlock"}>
                        <select
                            className={"small"}
                            onChange={this.handleChange.bind(this)}
                            value={this.state.year}
                        >
                            <option value={"2002"}>2002</option>
                            <option value={"2003"}>2003</option>
                            <option value={"2004"}>2004</option>
                            <option value={"2005"}>2005</option>
                            <option value={"2006"}>2006</option>
                            <option value={"2007"}>2007</option>
                            <option value={"2008"}>2008</option>
                            <option value={"2009"}>2009</option>
                            <option value={"2010"}>2010</option>
                            <option value={"2011"}>2011</option>
                            <option value={"2012"}>2012</option>
                            <option value={"2013"}>2013</option>
                            <option value={"2014"}>2014</option>
                            <option value={"2015"}>2015</option>
                            <option value={"2016"}>2016</option>
                            <option value={"2017"}>2017</option>
                            <option value={"2018"}>2018</option>
                            <option value={"2019"}>2019</option>
                            <option value={"2020"}>2020</option>
                            <option value={"2021"}>2021</option>
                            <option value={"2022"}>2022</option>
                        </select>
                    </div>
                </div>

                {
                    Object.keys(this.state.barData).length > 0 &&
                    <Bar id={"wagesIncrease"} data={this.state.barData}
                         options={this.state.barOptions}></Bar>
                }
                <div className={"top50FootNote"}>
                    *Wages data represents the entire state of Colorado.<br/>
                    *Not adjusted for inflation.
                </div>

            </div>
        );
    }

}

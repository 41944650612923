import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Line} from 'react-chartjs-2';

import axiosClient from '../../repo/backend'

ChartJS.register(...registerables);

class GapByCountyLine extends Component {
    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: []
        };

        this.lbl = [];
        this.lblAbbrev = [];
        this.values1 = [];
    }

    componentDidMount() {
        this.getChartData();
    }


    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.industryName !== prevProps.industryName) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/gapByCounty"
            + (this.props.industryName === "All Industries"
                ? ""
                : "/" + this.props.industryName
             );

        axiosClient.get(url).then(resGapByCounty => {

            this.lbl = [];
            this.lblAbbrev = [];
            this.values1 = [];
            this.values2 = [];

            resGapByCounty.data.forEach(element => {
                this.lbl.push(element["County Name"]);
                this.values1.push(element["Gap Intensity"]);
                this.values2.push(element["Desperation Index"]);
            });

            this.lbl.forEach(element => {
                this.lblAbbrev.push(element.substring(0, 30));
            });

            this.setState({
                barData: {
                    labels: this.lblAbbrev,
                    datasets: [
                        {
                            label: 'Effort Index',
                            data: this.values2,
                            backgroundColor: 'orange',
                            borderColor: 'orange',
                            borderWidth: 3,
                        },
                        {
                            label: 'Gap Intensity',
                            data: this.values1,
                            backgroundColor: 'red',
                            borderColor: 'red',
                            borderWidth: 3,
                        }
                    ],
                },
                barOptions: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ': ' + context.formattedValue;
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lblAbbrev,
                datasets: []
            },
        });
    }

    render() {
        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    Gap Intensity by County, for selected Industry
                </div>

                {Object.keys(this.state.barData).length > 0 &&
                    <Line id={"industryPostingsHiresPercent"} data={this.state.barData}
                          options={this.state.barOptions}></Line>
                }

            </div>
        );
    }

}

export default GapByCountyLine;

import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Chart} from 'react-chartjs-2';

import axiosClient from '../../repo/backend'

ChartJS.register(...registerables);

class JobsPostingsMultitype extends Component {

    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: [],
        };

        this.lbl = [];
        this.lblAbbrev = [];
        this.values1 = [];
        this.values2 = [];
        this.values3 = [];
        this.values4 = [];

    }

    componentDidMount() {
        this.getChartData();
    }

    handleBarClick(c, i) {
        if (i.length > 0 && this.props.industryName === "All Industries") {
            this.props.setIndustryName(this.lbl[i[0].index]);
        } else {
            this.props.setIndustryName("All Industries");
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.industryName !== this.props.industryName
            || prevProps.countyName !== this.props.countyName
            || prevProps.orderBy !== this.props.orderBy
            || prevProps.topSelect !== this.props.topSelect
        ) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/jobsPostings"
            + "/orderBy/" + this.props.orderBy
            + "/topName/" + this.props.topSelect
            + (this.props.countyName === "Colorado State" ? "" : "/county/" + this.props.countyName)
            + (this.props.industryName === "All Industries" ? "" : "/industry/" + this.props.industryName);

        axiosClient.get(url).then(resDespGap => {

            this.lbl = [];
            this.lblAbbrev = [];
            this.values1 = [];
            this.values2 = [];
            this.values3 = [];
            this.values4 = [];

            resDespGap.data.forEach(element => {
                this.lbl.push(element["Industy Name"]);
                this.values1.push(element["Current jobs"]);
                this.values2.push(element["Unique postings"]);
                this.values3.push(element["Desperation Index"]);
                this.values4.push(element["Gap Intensity"]);
            });

            this.lbl.forEach(element => {
                this.lblAbbrev.push(element.substring(0,30));
            });

            this.setState({
                barData: {
                    labels: this.lblAbbrev,
                    datasets: [
                        {
                            type: 'line',
                            yAxisID: 'y-line',
                            label: 'Effort Index',
                            data: this.values3,
                            borderColor: '#FFA100',
                            backgroundColor: '#FFA100',
                            borderWidth: 2,
                            fill: false,
                        },
                        {
                            type: 'line',
                            yAxisID: 'y-line',
                            label: 'Gap Intensity',
                            data: this.values4,
                            borderColor: '#EC5A28',
                            backgroundColor: '#EC5A28',
                            borderWidth: 3,
                            fill: false,
                        },
                        {
                            type: 'bar',
                            yAxisID: 'y-bar',
                            label: 'Jobs',
                            data: this.values1,
                            backgroundColor: '#652D8C',
                        },
                        {
                            type: 'bar',
                            yAxisID: 'y-bar',
                            label: 'Postings',
                            data: this.values2,
                            backgroundColor: '#87C13F',
                        },
                    ],
                },
                barOptions: {
                    onClick: (c,i) => {
                        this.handleBarClick(c,i);
                    },
                    responsive: true,
                    scales: [
                        {
                            id: 'y-line',
                            stacked: false,
                            display: false,
                            position: 'right',
                            type: 'linear',
                            scaleLabel: {
                                display: true,
                            },
                            ticks: {
                                max: 10,
                                display: true,
                                fontSize: 13,
                                padding: 10,
                                beginAtZero: true
                            }
                        },
                        {
                            id: 'y-bar',
                            stacked: false,
                            display: false,
                            position: 'left',
                            type: 'linear',
                            scaleLabel: {
                                display: false,
                            },
                        }

                    ],
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ': ' + context.formattedValue;
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lblAbbrev,
                datasets: [
                    {
                        type: 'line',
                        yAxisID: 'y-line',
                        label: 'Effort Index',
                        data: this.values3,
                        borderColor: 'gray',
                        backgroundColor: 'gray',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        type: 'line',
                        yAxisID: 'y-line',
                        label: 'Gap Intensity',
                        data: this.values4,
                        borderColor: 'gray',
                        backgroundColor: 'gray',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        type: 'bar',
                        yAxisID: 'y-bar',
                        label: 'Jobs',
                        data: this.values1,
                        backgroundColor: 'gray',
                    },
                    {
                        type: 'bar',
                        yAxisID: 'y-bar',
                        label: 'Postings',
                        data: this.values2,
                        backgroundColor: 'lightgray',
                    },
                ],
            },
        });

    }

    render() {
        const barData = this.state.barData;
        let dataLength = -1;
        if (typeof barData !== "undefined") {
            if (Object.keys(barData).length > 0 && Object.keys(barData.datasets[0].data).length > 0) {
                dataLength = Object.keys(this.state.barData.datasets[0].data).length;
            } else {
                dataLength = 0;
            }

        }

        return (
            <div className={"component"}>
                {dataLength === 0 && <div className={"graphCenter noResults"}><br/>No results</div>}
                <div className={"alignCenter"}>
                    <div className={"title sectionTitle"}>
                        Jobs and postings for selected County and Industry
                    </div>
                    {Object.keys(this.state.barData).length > 0 &&
                        <Chart id={"jobPostingsWithDesperationIndex"}
                               data={this.state.barData}
                               options={this.state.barOptions}
                               type='bar'
                        />
                    }
                </div>
                <div className={"top50FootNote"}>
                    *To access the underlying 6-digit industry data click on the bar graph
                    for the desired industry. After that, click on any white space
                    in the graph to return to the 2-digit view.
                </div>

            </div>
        );
    }
}

export default JobsPostingsMultitype;

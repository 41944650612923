import React, {Component} from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import axiosClient from '../../repo/backend'

import "./DespGapBar.css"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class DespGapBar extends Component {
    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: [],
            despGapHelpX: 0,
            despGapHelpY: 0,
            despGapHelpVisible: false
        };

        this.lbl = [];
        this.lblAbbrev = [];
        this.values1 = [];
        this.values2 = [];

        this.handleOrderBy.bind(this);
        this.onTopSelectChange.bind(this)
    }

    componentDidMount() {
        this.getChartData();
    }

    handleOrderBy(orderBySelected) {
        this.props.setOrderBy(orderBySelected);
    }

    handleBarClick(c, i) {
        if (i.length > 0 && this.props.industryName === "All Industries") {
            this.props.setIndustryName(this.lbl[i[0].index]);
        } else {
            this.props.setIndustryName("All Industries");
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.industryName !== this.props.industryName
            || prevProps.countyName !== this.props.countyName
            || prevProps.orderBy !== this.props.orderBy
            || prevProps.topSelect !== this.props.topSelect
        ) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/jobsPostings"
            + "/orderBy/" + this.props.orderBy
            + "/topName/" + this.props.topSelect
            + (this.props.countyName === "Colorado State" ? "" : "/county/" + this.props.countyName)
            + (this.props.industryName === "All Industries" ? "" : "/industry/" + this.props.industryName);

        axiosClient.get(url).then(resDespGap => {

            this.lbl = [];
            this.lblAbbrev = [];
            this.values1 = [];
            this.values2 = [];

            resDespGap.data.forEach(element => {
                this.lbl.push(element["Industy Name"]);
                this.values1.push(element["Desperation Index"]);
                this.values2.push(element["Gap Intensity"]);
            });

            this.lbl.forEach(element => {
                this.lblAbbrev.push(element.substring(0, 30));
            });

            this.setState({
                barData: {
                    labels: this.lblAbbrev,
                    datasets: [
                        {
                            label: 'Effort Index',
                            data: this.values1,
                            backgroundColor: '#FFA100',
                        },
                        {
                            label: 'Gap Intensity',
                            data: this.values2,
                            backgroundColor: '#EC5A28',
                        },
                    ],
                },
                barOptions: {
                    onClick: (c, i) => {
                        this.handleBarClick(c, i);
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            onHover: (e) => {
                                this.setState({
                                    despGapHelpY: e.native.layerY + 20,
                                    despGapHelpX: e.native.layerX - 330,
                                    despGapHelpVisible: true
                                });
                            },
                            onLeave: () => {
                                this.setState({
                                    despGapHelpVisible: false
                                });
                            }
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ': ' + context.formattedValue;
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lblAbbrev,
                datasets: [
                    {
                        label: 'Effort Index',
                        data: this.values1,
                        backgroundColor: 'gray',
                    },
                    {
                        label: 'Gap Intensity',
                        data: this.values2,
                        backgroundColor: 'lightgray',
                    },
                ],
            },
        });
    }

    onTopSelectChange(e) {
        this.props.setTopSelect(e.target.value);
    }


    render() {

        const DespGapHelp = () => {
            return (
                <div className={"instructions"}
                     style={{
                         display: ((this.state.despGapHelpVisible) ? "block" : "none"),
                         position: "absolute",
                         top: this.state.despGapHelpY,
                         left: this.state.despGapHelpX
                     }}>
                    <div className={"footNotesBody"}>
                        <p className={"indexKeyTitle alignLeft"}>
                            Effort Index:
                        </p>
                        <p className={"indexKeyBody alignLeft"}>
                            We developed this index as an easy way to quickly discern how difficult
                            it is to fill positions by industry and within different counties. The value displayed is
                            the
                            ratio of the total number of job postings divided by the number of unique jobs posted. In
                            other
                            words, if there are 4 unique jobs that are posted, and a total of 12 postings, then on
                            average
                            each job had to be posted 3 times. The “Effort Index” in this case would be “3”, i.e.,
                            12
                            total postings divided by 4 unique postings.
                        </p>
                        <p className={"indexKeyTitle alignLeft"}>
                            Gap intensity:
                        </p>
                        <p className={"indexKeyBody alignLeft"}>
                            Gap Intensity modifies the Effort Index based upon the ratio between
                            the total number of jobs and the unique postings in an industry. If there are a lot of
                            posting
                            in a particular industry, for example, the Gap Intensity will be greater. But if the
                            industry
                            only has a few postings compared to the number of existing jobs, the Gap Intensity will be
                            lower.
                            In other words, the Gap Intensity is a measure of the relative magnitude of the total hiring
                            challenge.
                        </p>
                        <div className={"clearFloat"}></div>
                    </div>

                </div>
            );
        }

        const barData = this.state.barData;
        let dataLength = -1;
        if (typeof barData !== "undefined") {
            if (Object.keys(barData).length > 0 && Object.keys(barData.datasets[0].data).length > 0) {
                dataLength = Object.keys(this.state.barData.datasets[0].data).length;
            } else {
                dataLength = 0;
            }
        }

        return (
            <div>
                <DespGapHelp/>
                <div className={"component alignCenter"}>
                    <div className={"title sectionTitle"}>
                        Effort Index, Gap Intensity by Industry, for Selected County
                    </div>

                    <div>
                        {dataLength === 0 && <div className={"graphCenter noResults"}>No results</div>}

                        {Object.keys(this.state.barData).length > 0 &&
                            <Bar id={"industryPostingsHiresPercent"} data={this.state.barData}
                                 options={this.state.barOptions}></Bar>
                        }
                    </div>

                    <div className={"top50FootNote"}>
                        *To access the underlying 6-digit industry data click on the bar graph
                        for the desired industry. After that, click on any white space
                        in the graph to return to the 2-digit view.
                    </div>

                </div>
                <div className={"title orderByBody"}>
                    <div className={"orderByCell orderByTitle"}>
                        Order by:
                    </div>
                    <div className={"orderByCell orderByRadioWide"}>
                        <label>
                            <input type={"radio"}
                                   name={"orderBy"}
                                   id={"orderBy1"}
                                   checked={this.props.orderBy === "desperation_index"}
                                   onClick={this.handleOrderBy.bind(this, "desperation_index")}
                            />
                            Effort Index
                        </label>
                    </div>
                    <div className={"orderByCell orderByRadio"}>
                        <label>
                            <input type={"radio"}
                                   name={"orderBy"}
                                   id={"orderBy2"}
                                   checked={this.props.orderBy === "gap_intensity"}
                                   onClick={this.handleOrderBy.bind(this, "gap_intensity")}
                            />
                            Gap Intensity
                        </label>
                    </div>
                    <div className={"orderByCell orderByRadio"}>
                        <label>
                            <input type={"radio"}
                                   name={"orderBy"}
                                   id={"orderBy3"}
                                   checked={this.props.orderBy === "current_jobs"}
                                   onClick={this.handleOrderBy.bind(this, "current_jobs")}/>
                            Current Jobs
                        </label>
                    </div>
                    <div className={"orderByCell orderByRadio"}>
                        <label>
                            <input type={"radio"}
                                   name={"orderBy"}
                                   id={"orderBy4"}
                                   checked={this.props.orderBy === "unique_postings"}
                                   onClick={this.handleOrderBy.bind(this, "unique_postings")}/>
                            Unique Postings
                        </label>
                    </div>
                    <div className={"orderByCell orderByRadio"}>
                        <label>
                            <input type={"radio"}
                                   name={"orderBy"}
                                   id={"orderBy5"}
                                   checked={this.props.orderBy === "alphabetical"}
                                   onClick={this.handleOrderBy.bind(this, "alphabetical")}/>
                            Alphabetical
                        </label>
                    </div>
                    <div className={"orderByCell orderByRadio"}>

                        <select id={"topSelect"}
                                className={"large"}
                                onChange={this.onTopSelectChange.bind(this)}
                                value={this.props.topSelect}
                        >
                            <option value={"all"}>All</option>
                            <option value={"top.5"}>Top 5</option>
                            <option value={"top.10"}>Top 10</option>
                            <option value={"bottom.10"}>Bottom 10</option>
                            <option value={"bottom.5"}>Bottom 5</option>
                            <option value={"bottom.2"}>Bottom 2</option>
                            <option value={"bottom.1"}>Bottom 1</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default DespGapBar;

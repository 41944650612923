import React, {Component} from "react";
import coloradoMap from "../../img/map/coloradoMap.png"
import "./ColoradoMap.css"

export default class ColoradoMap extends Component {

    constructor(props) {
        super(props);
        this.handleClick.bind(this);
        this.handleReset.bind(this);
    }

    handleClick(countyName) {
        this.props.setCountyName(countyName);
    }

    handleReset() {
        this.props.setCountyName("Colorado State");
    }

    render() {
        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    For detailed information please click a county in the map
                </div>
                <div className={"gap"}></div>
                <img
                    src={coloradoMap}
                    useMap="src/pages/graphs/ColoradoMap#counties"
                    className={"stateMap"}
                    alt={""}
                />

                <div className={"alignCenter"}>
                    <button className={"big selected "
                        + (this.props.countyName === "Colorado State"
                            ? " invisible"
                            : "")
                    }
                            onClick={this.handleReset.bind(this)}
                    >select state
                    </button>
                </div>

                <map name="counties">
                    <area onClick={this.handleClick.bind(this, "Moffat County")}
                          alt="Moffat County" title="Moffat County"
                          coords="143,91,11,7"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Weld County")}
                          alt="Weld County" title="Weld County"
                          coords="368,7,477,54"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Weld County")}
                          alt="Weld County" title="Weld County"
                          coords="366,55,428,91"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Broomfield County")}
                          alt="Broomfield County" title="Broomfield County"
                          coords="337,137,377,115,422,115,423,94,355,92,354,117"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Boulder County")}
                          alt="Boulder County" title="Boulder County"
                          coords="305,92,347,123"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Morgan County")}
                          alt="Morgan County" title="Morgan County"
                          coords="436,62,488,114"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Logan County")}
                          alt="Logan County" title="Logan County"
                          coords="494,64,558,8"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Sedgwick County")}
                          alt="Sedgwick County" title="Sedgwick County"
                          coords="567,7,611,30"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Phillips County")}
                          alt="Phillips County" title="Phillips County"
                          coords="567,35,611,63"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Yuma County")}
                          alt="Yuma County" title="Yuma County"
                          coords="555,71,611,159"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Washington County")}
                          alt="Washington County" title="Washington County"
                          coords="477,123,475,161,545,160,545,72,496,72,495,123"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Garfield County")}
                          alt="Garfield County" title="Garfield County"
                          coords="9,160,9,184,169,185,178,112,160,111,152,136,107,140,104,155,51,155"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Summit County")}
                          alt="Summit County" title="Summit County"
                          coords="236,139,259,160,250,179,267,189,284,164,268,154,257,137,242,129"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Grand County")}
                          alt="Grand County" title="Grand County"
                          coords="220,80,221,122,249,122,266,136,270,149,277,148,282,134,291,133,299,108,295,91,284,70,263,84"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Gilpin County")}
                          alt="Gilpin County" title="Gilpin County"
                          coords="299,127,297,134,322,146,323,127"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Clear Creek County")}
                          alt="Clear Creek County" title="Clear Creek County"
                          coords="284,144,280,154,292,157,290,165,320,164,320,149,310,146,299,138"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Jefferson County")}
                          alt="Jefferson County" title="Jefferson County"
                          coords="326,129,325,214,346,182,352,164,344,163,350,154,351,134,335,139,335,130"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Denver County")}
                          alt="Denver County" title="Denver County"
                          coords="355,143,352,156,368,157,369,147,378,142,391,138,395,121,366,121,364,133"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Adams County")}
                          alt="Adams County" title="Adams County"
                          coords="398,122,389,144,467,145,468,121"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Arapahoe County")}
                          alt="Arapahoe County" title="Arapahoe County"
                          coords="374,151,372,164,467,165,467,151"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Mesa County")}
                          alt="Mesa County" title="Mesa County"
                          coords="10,194,9,280,59,281,61,246,84,232,100,219,119,219,145,199,132,194"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Delta County")}
                          alt="Delta County" title="Delta County"
                          coords="68,265,138,265,137,214,99,225,87,242,68,251"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Pitkin County")}
                          alt="Pitkin County" title="Pitkin County"
                          coords="150,193,165,213,182,216,190,226,201,228,211,219,216,225,228,194"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Lake County")}
                          alt="Lake County" title="Lake County"
                          coords="224,223,253,223,255,189,232,193"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Park County")}
                          alt="Park County" title="Park County"
                          coords="262,199,259,227,269,234,277,244,279,261,326,261,326,218,318,218,319,170,289,170"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Douglas County")}
                          alt="Douglas County" title="Douglas County"
                          coords="337,213,382,212,385,171,355,171"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Elbert County")}
                          alt="Elbert County" title="Elbert County"
                          coords="391,171,391,211,442,212,444,241,465,239,465,171"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Lincoln County")}
                          alt="Lincoln County" title="Lincoln County"
                          coords="444,248,444,279,512,277,514,170,474,170,474,246"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Kit Carson County")}
                          alt="Kit Carson County" title="Kit Carson County"
                          coords="611,222,523,171"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Cheyenne County")}
                          alt="Cheyenne County" title="Cheyenne County"
                          coords="522,230,610,270"
                          shape="rect"/>
                    <area onClick={this.handleClick.bind(this, "Gunnison County")}
                          alt="Gunnison County" title="Gunnison County"
                          coords="144,209,145,310,134,310,138,322,180,320,182,288,241,290,229,264,229,246,210,230,192,234"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Chaffee County")}
                          alt="Chaffee County" title="Chaffee County"
                          coords="223,227,250,227,269,243,272,263,279,277,263,290,249,289,236,264,247,240,232,238"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Jackson County")}
                          alt="Jackson County" title="Jackson County"
                          coords="202,7,252,7,279,63,273,74,251,78,235,75,220,72,214,51,219,25"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Routt County")}
                          alt="Routt County" title="Routt County"
                          coords="149,87,182,88,186,124,213,125,209,62,206,43,210,24,193,14,190,7,159,6,158,53,150,59"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Larimer County")}
                          alt="Larimer County" title="Larimer County"
                          coords="259,8,285,58,302,85,349,84,350,74,360,73,360,7"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Teller County")}
                          alt="Teller County" title="Teller County"
                          coords="332,219,333,261,341,266,359,266,361,255,349,251,352,242,351,220"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "El Paso County")}
                          alt="El Paso County" title="El Paso County"
                          coords="359,219,357,249,367,251,366,281,437,280,437,219"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Kiowa County")}
                          alt="Kiowa County" title="Kiowa County"
                          coords="492,287,492,309,611,306,611,277,521,275,520,286"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Montrose County")}
                          alt="Montrose County" title="Montrose County"
                          coords="9,292,8,322,93,323,80,302,124,301,138,306,136,272,68,270,68,290"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Ouray County")}
                          alt="Ouray County" title="Ouray County"
                          coords="91,307,127,306,136,335,126,349,109,338,106,324"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "San Juan County")}
                          alt="San Juan County" title="San Juan County"
                          coords="103,378,139,377,133,351,120,357"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "San Miguel County")}
                          alt="San Miguel County" title="San Miguel County"
                          coords="9,328,9,349,74,348,81,355,98,352,109,361,117,350,102,338,95,328"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Dolores County")}
                          alt="Dolores County" title="Dolores County"
                          coords="9,357,9,386,16,378,96,377,96,371,104,370,103,362,89,360,80,363,74,356"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Montezuma County")}
                          alt="Montezuma County" title="Montezuma County"
                          coords="9,398,20,384,92,383,90,400,79,411,68,425,61,447,8,446"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "La Plata County")}
                          alt="La Plata County" title="La Plata County"
                          coords="70,447,139,447,138,384,101,383,91,410,80,418"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Hinsdale County")}
                          alt="Hinsdale County" title="Hinsdale County"
                          coords="139,328,180,329,180,340,167,349,170,401,145,402,145,365,135,344"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Archuleta County")}
                          alt="Archuleta County" title="Archuleta County"
                          coords="146,407,146,448,222,447,216,436,210,427,207,411,172,410,161,405"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Mineral County")}
                          alt="Mineral County" title="Mineral County"
                          coords="176,406,206,404,207,358,187,348,175,351"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Saguache County")}
                          alt="Saguache County" title="Saguache County"
                          coords="190,298,188,341,221,354,224,366,315,364,315,355,268,297"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Fremont County")}
                          alt="Fremont County" title="Fremont County"
                          coords="282,268,333,267,334,273,360,272,360,309,289,309,273,292,284,284"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Custer County")}
                          alt="Custer County" title="Custer County"
                          coords="295,314,351,316,351,344,345,335,333,345,322,352,310,344"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Eagle County")}
                          alt="Eagle County" title="Eagle County"
                          coords="179,130,178,188,247,185,244,170,252,159,233,141,229,129"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Rio Blanco County")}
                          alt="Rio Blanco County" title="Rio Blanco County"
                          coords="9,95,8,155,41,151,95,151,98,138,141,133,142,124,151,122,152,106,176,105,176,96"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Rio Grande County")}
                          alt="Rio Grande County" title="Rio Grande County"
                          coords="210,360,213,405,264,405,264,372,220,372,218,360"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Conejos County")}
                          alt="Conejos County" title="Conejos County"
                          coords="216,409,262,409,290,417,289,447,232,448,221,429,213,422"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Alamosa County")}
                          alt="Alamosa County" title="Alamosa County"
                          coords="271,372,270,407,291,408,311,386,312,372"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Costilla County")}
                          alt="Costilla County" title="Costilla County"
                          coords="297,449,343,448,342,403,337,385,321,386,297,412"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Huerfano County")}
                          alt="Huerfano County" title="Huerfano County"
                          coords="318,382,323,353,334,353,343,340,356,357,388,356,409,361,386,397,348,414,348,397,338,380"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Pueblo County")}
                          alt="Pueblo County" title="Pueblo County"
                          coords="357,315,358,350,388,348,436,367,437,286,366,285,366,311"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Las Animas County")}
                          alt="Las Animas County" title="Las Animas County"
                          coords="349,422,347,449,522,447,520,385,437,383,436,370,414,365,389,402,366,414"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Crowley County")}
                          alt="Crowley County" title="Crowley County"
                          coords="443,286,444,324,474,326,474,317,484,317,485,286"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Otero County")}
                          alt="Otero County" title="Otero County"
                          coords="443,328,472,330,482,324,489,315,494,315,494,378,442,376"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Bent County")}
                          alt="Bent County" title="Bent County"
                          coords="500,315,552,315,551,379,500,378"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Prowers County")}
                          alt="Prowers County" title="Prowers County"
                          coords="559,314,612,314,612,378,557,380"
                          shape="poly"/>
                    <area onClick={this.handleClick.bind(this, "Baca County")}
                          alt="Baca County" title="Baca County"
                          coords="530,384,612,383,612,449,529,448"
                          shape="poly"/>
                </map>
            </div>
        );
    }
}


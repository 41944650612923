import React, {Component} from "react";
import CareerTable from "./tables/CareerTable";
import {Link} from "react-router-dom";
import IndexKeyFootnotes from "./tables/career_table/IndexKeyFootnotes";
import tablesBanner from "../img/banner/tables.png";
import "./Tables.css";
import FlagKeyFootnotes from "./tables/career_table/FlagKeyFootnotes";
import SourcesFootnotes from "./SourcesFootnotes";
import new_clipart from "../img/clipart/new.png";

import pdf from "../pdf/CO Occupation Demand Narrative_7-2022.pdf"
import etplCsv from "../csv/ETPLPrograms2024.csv"
import ZoomAdjuster from "../ZoomAdjuster";
import ReactGA from "react-ga4";
import red_play_button_clipart from "../img/clipart/redPlayButton.png";
import VimeoDemoVideo from "../video/demo/VimeoDemoVideo";

class Tables extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDemoVideo: false,
            showTableFootnotes: true,
            showInstructions: false,
            showIndexKey: false,
            showFlagKey: false
        }
        this.shouldShowTableFootnotes.bind(this);
        this.handleInstructionsClick.bind(this);
        this.handleIndexKeyClick.bind(this);
        this.handleFlagKeyClick.bind(this);
    }


    componentDidMount() {
        document.querySelector(".page").scrollIntoView();
        ReactGA.send({hitType: "pageview", page: "/table"});
        console.log("pageview sent");
    }

    handleIndexKeyClick() {
        this.setState({
            showIndexKey: !this.state.showIndexKey
        });
        document.getElementById("bottomOfThePage").scrollIntoView();
    }

    handleFlagKeyClick() {
        this.setState({
            showFlagKey: !this.state.showFlagKey
        });
        document.getElementById("bottomOfThePage").scrollIntoView();
    }

    shouldShowTableFootnotes(s) {
        if (s === true) {
            this.setState({
                showTableFootnotes: true
            })
        } else {
            this.setState({
                showTableFootnotes: false
            })
        }
    }

    handleInstructionsClick() {
        this.setState({
            showInstructions: !this.state.showInstructions
        });
    }

    render() {
        const NavigationButtons = (props) => {
            return (
                <div className={"navigation"}>
                    <div className={"inlineBlock"}>
                        <button className={"nav" + (this.state.showInstructions ? " navOpen" : "")}
                                onClick={this.handleInstructionsClick.bind(this)}>Instructions
                        </button>
                        <div className={"inlineBlock demoVideoDesktop"}>
                            <button className={"nav" + (this.state.showDemoVideo ? " navOpen" : "")}
                                    onClick={() => props.show()}>
                                <div>
                                    <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                                    <div style={{
                                        verticalAlign: "middle",
                                        display: "inline-block",
                                        paddingLeft: "10px"
                                    }}>
                                        <img src={"https://www.pngkey.com/png/detail/3-33600_youtube-icon-block-play-button-icon-red.png"}
                                             alt={""}
                                             style={{width: "30px"}}/>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className={"inlineBlock"}
                         style={{width: "calc(100% - 1280px)"}}></div>
                    <div style={{width: "750px", display: "inline-block"}}>
                        <button className={"nav"}>
                            <Link to="/graphs"
                                  className={"linkDecoration"}>Industry Demand</Link>
                        </button>
                        <button className={"nav navSelected"}
                                disabled={true}>
                            <Link to="/tables"
                                  className={"linkDecoration"}>Occupation Demand</Link>
                        </button>
                        <button className={"nav"}>
                            <Link to="/piecharts"
                                  className={"linkDecoration"}>Voices of Colorado</Link>
                        </button>
                    </div>
                </div>
            );
        }

        const Instructions = () => {
            return (
                <div className={"alignLeft"}>
                    {this.state.showInstructions &&
                        <div className={"instructions inlineBlock"}>
                            <div className={"inlineBlock"}>
                                <b>Instructions for the table:</b>
                                <ol>
                                    <li>Choose a SOC category by using the dropdown.</li>
                                    <li>If you wish to see all SOC categories, select "All Groups" in the dropdown.</li>
                                    <li>Click each icon to enable a filter to the selected criteria for the selected SOC
                                        category.
                                    </li>
                                    <li>Click on the same icon again to disable filter.</li>
                                    <li>Click on the ETPL buttons to view a list of approved ETPL providers for that
                                        occupation.
                                    </li>
                                    <li>Sort each column from high to low or low to high by clicking on the header.</li>
                                    <li>Use the Command Key (Mac) or Control Key (PC) + and - keys to scale the page to
                                        your
                                        screen size.
                                        Site not designed for small screens.
                                    </li>
                                </ol>
                            </div>
                        </div>

                    }
                </div>
            );
        }

        const IndexKey = () => {
            return (
                <div className={"inlineBlock"}>
                    {this.state.showTableFootnotes &&
                        <div className={"tableFootNotes"}>
                            <div className={"gap"}></div>
                            <button className={"big "
                                + ((this.state.showIndexKey) ? "bigSelected" : "")
                                + ((this.state.showFlagKey) ? "invisible" : "")
                            }
                                    onClick={this.handleIndexKeyClick.bind(this)}>Challenge Index Key
                            </button>
                        </div>
                    }
                </div>
            );
        }

        const FlagKey = () => {
            return (
                <div className={"inlineBlock"}>
                    {this.state.showTableFootnotes &&
                        <div className={"tableFootNotes"}>
                            <div className={"gap"}></div>
                            <button className={
                                "big "
                                + ((this.state.showFlagKey) ? "bigSelected" : "")
                                + ((this.state.showIndexKey) ? "invisible" : "")
                            }
                                    onClick={this.handleFlagKeyClick.bind(this)}>Data Flag / Icons Key
                            </button>
                        </div>
                    }
                </div>
            );
        }

        ZoomAdjuster();

        return (
            <div className={"page"}>

                <img src={tablesBanner}
                     className={"title"}
                     alt={""}/>

                <div className={"alignCenter"}>
                    <NavigationButtons show={() => {
                        this.setState({
                            showDemoVideo: true
                        })
                    }}/>
                    <Instructions/>
                    <VimeoDemoVideo showDemoVideo={this.state.showDemoVideo}
                                    show={() => {
                                        this.setState({
                                            showDemoVideo: true
                                        })
                                    }}
                                    hide={() => {
                                        this.setState({
                                            showDemoVideo: false
                                        })
                                    }}/>

                    <div className={"alignCenter demoVideoMobile"}>
                        <button className={"nav"}
                                style={{width: "40%", backgroundColor: "white", borderRadius: "20px"}}
                                onClick={() => {
                                    this.setState({showDemoVideo: true})
                                }}>
                            <div style={{zoom: "150%"}}>
                                <div className={"smallGap"}></div>
                                <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                                <div style={{verticalAlign: "middle", display: "inline-block", paddingLeft: "10px"}}>
                                    <img src={red_play_button_clipart}
                                         alt={""}
                                         style={{width: "30px"}}/>
                                </div>
                            </div>
                        </button>
                    </div>

                    <div className={"gap"}></div>
                    <div className={"component careerTableBoundary inlineBlock"}>
                        <div className={"title pageTitle"}>
                            New Methodologies for Estimating a Rapidly Changing Labor Market
                        </div>

                        <CareerTable shouldShowTableFootnotes={this.shouldShowTableFootnotes.bind(this)}/>
                        <div className={"alignLeft"}>
                            <IndexKey/>
                            <FlagKey/>
                        </div>
                        {this.state.showIndexKey &&
                            <IndexKeyFootnotes showTableFootnotes={this.state.showTableFootnotes}/>
                        }
                        {this.state.showFlagKey &&
                            <FlagKeyFootnotes showTableFootnotes={this.state.showTableFootnotes}/>
                        }

                    </div>

                    <div className={"largeGap"}>&nbsp;</div>

                    <div>
                        <div className={"footNotesShape inlineBlock"}>
                            <SourcesFootnotes pdf={pdf}
                                              etplCsv={etplCsv}>
                                <li>ETPL program names and information are from the <b>2024 CO ETPL Approved Provider
                                    list.</b>
                                    <img src={new_clipart}
                                         style={{width: "45px"}}
                                         alt={""}/>
                                </li>
                                <li>Occupation numbers and projected growth are from Colorado Department of Labor &
                                    Employment,
                                    Office of Labor Market Information and the Colorado Department of Higher Education.
                                </li>
                                <li>Regional Completion Data is from SURDS.Colorado.gov</li>
                                <li>The correlation of program name to SOC codes was matched using the SOC/CIP Crosswalk
                                    from
                                    the National Center for Education Statistics (NCES).
                                </li>
                                <li>Tier 1 & 2 jobs are calculated from the 2023 CO Talent Pipeline Report formulas.
                                </li>
                                <li>See Index Key for details on the Challenge Index.</li>
                                <li>See Flag Key for details on the icon flags formulas.</li>
                            </SourcesFootnotes>
                        </div>
                    </div>

                    <div className={"largeGap"}>&nbsp;</div>

                    <div id={"bottomOfThePage"}>&nbsp;</div>
                </div>
            </div>
        )
    }
}

export default Tables;

import React from 'react';

const topOccupationsColumns = () => {
    return [
        {
            key: "row_num",
            label:
                <div className={"top50cell top50cellRownum top50cellLabel"}>#</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellRownum"}>
                    {row.row_num}
                </div>;
            }
        },
        {
            key: "skill",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"}>Common Skill</div>,
            cell: (row, columnKey) => {
                const textMaxLength = (window.outerWidth < 800) ? 24 : 45;

                return <div className={"top50cell top50cellCommonSkill"}>
                    {row.skill.substring(0, textMaxLength)}
                </div>;
            }
        },
        {
            key: "postings",
            label:
                <div className={"top50cell top50cellCommonPostings top50cellLabel"}>Postings</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellCommonPostings"}>
                    {row.postings}
                </div>;
            }
        },
        {
            key: "profiles",
            label:
                <div className={"top50cell top50cellCommonProfiles top50cellLabel"}>Profiles</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellCommonProfiles"}>
                    {row.profiles}
                </div>;
            }
        },
    ];
}

export default topOccupationsColumns();

import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Chart} from 'react-chartjs-2';

import axiosClient from '../../repo/backend'

ChartJS.register(...registerables);

export default class WagesBar1IndustryAllYears extends Component {
    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: [],
            showNoRecords: false
        };

        this.lbl = [];
        this.values1 = [];
        this.values2 = [];
    }

    componentDidMount() {
        this.getChartData();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.industryName !== this.props.industryName) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/wages/industryName/"
            + (
                this.props.industryName === "All Industries"
                    ? "all"
                    : this.props.industryName
            );
        axiosClient.get(url).then(resGapByCounty => {

            this.lbl = [];
            this.values1 = [];
            this.values2 = [];

            resGapByCounty.data.forEach(element => {
                this.lbl.push(element["year"]);
                this.values1.push(parseFloat(element["wages_increase_pct"]));
                this.values2.push(parseFloat(element["inflation_rate"]));
            });

            this.setState({
                showNoRecords: this.values1.length === 0,
                barData: {
                    labels: this.lbl,
                    datasets: [
                        {
                            type: 'line',
                            label: 'US CPI-U Rate % from 2001',
                            data: this.values2,
                            backgroundColor: '#E70087',
                            borderColor: '#E70087',
                            borderWidth: 2,
                        },
                        {
                            type: 'bar',
                            label: 'Colorado Wages Increase % from 2001',
                            data: this.values1,
                            backgroundColor: '#87C13F',
                        },
                    ],
                },
                barOptions: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ': ' + context.formattedValue;
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lbl,
                datasets: []
            },
        });
    }

    render() {

        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    Annual Wages increase compared to US Consumer Price Index
                </div>

                {this.state.showNoRecords && <div className={"graphCenterRelative noResults"}>No results</div>}

                {Object.keys(this.state.barData).length > 0 &&
                    <Chart id={"wagesIncrease"} data={this.state.barData}
                           options={this.state.barOptions}></Chart>
                }
                <div className={"top50FootNote"}>
                    *Wages data represents the state of <b>Colorado</b>.
                    Inflation data is based on the <b>US</b> Consumer Price Index (CPI)
                    for All Urban Consumers from the Bureau of Labor Statistics
                    Base Period 1982-84=100. Annual Averages - readjusted for 2001 as the base.
                </div>

            </div>
        );
    }

}

import React, {Component} from "react";

class IndexKeyFootnotes extends Component {

    render() {
        return (
            <div>
                {this.props.showTableFootnotes && <div>
                    <div className={"tableFootNotes"}>
                        <div className={"columns5"}>
                            <p className={"indexKeyTitle alignLeft"}>
                                Overall Challenge Index Key:
                            </p>
                            <p className={"indexKeyBody alignLeft"}>
                                1 = Least Challenge, 5 = Most Challenge
                            </p>
                        </div>
                        <div className={"columns5 alignLeft"}>
                            <ul className={"footNotesListWithBullets"}>
                                <li>Overall Challenge Index Calculation Weightings</li>
                                <li>Work from Home: Weight = 1</li>
                                <li>Stress Level: Weight =2</li>
                                <li>Pay: Weight = 4</li>
                                <li>Education/Experience Needed: Weight = 3</li>
                                <li>Hours Worked/Demand: Weight = 2</li>
                                <li>Safety/Physical Demand: Weight = 2</li>
                            </ul>
                        </div>
                    </div>
                    <div className={"clearFloat tableFootNotes"}>
                        <div>
                            <div className={"columns3"}>

                                <p className={"indexKeyTitle floatLeft"}>
                                    Work from Home
                                </p>
                                <p className={"indexKeyBody"}>
                                    Coding this where we think it’s heading/what’s possible rather than what has
                                    been in the past)
                                    <br/>
                                </p>
                                <ol className={"footNotesList"}>
                                    <li> Work from home all the time, never have to do anything in person, and have
                                        a
                                        flexible
                                        schedule. (this is difficult to determine because every company is
                                        different, but we
                                        will score it if it’s possible, not if the companies allow it, so computer
                                        programming
                                        and most information jobs are possible so those would be a 1).
                                    </li>
                                    <li> Work from home most of the time, with occasional in-person meetings or
                                        events.
                                        (i.e.
                                        admin jobs are often moving in this direction)
                                    </li>
                                    <li> Hybrid work from home with a more regular schedule (i.e. some government
                                        jobs,
                                        insurance, relators, some engineering jobs).
                                    </li>
                                    <li> Some work-from-home options but mostly in office. (i.e. some healthcare
                                        jobs that
                                        are
                                        moving to telehealth but most jobs still aren’t)
                                    </li>
                                    <li> No foreseeable ability to work from home (i.e. hospitality, most health,
                                        manufacturing, transportation, etc).
                                    </li>
                                </ol>
                            </div>
                            <div className={"columns3"}>
                                <p className={"indexKeyTitle"}>
                                    Stress Level
                                </p>
                                <p className={"indexKeyBody"}>
                                    <ol className={"footNotesList"}>
                                        <li> No stress at all, easy low stakes job, minimal deadlines, take no work home
                                            (i.e.
                                            landscaping, massage therapist)
                                        </li>
                                        <li> Minimal stress with deadlines, some cranky customers, or subject matter
                                            (i.e.
                                            waitress,
                                            retail)
                                        </li>
                                        <li> Normal stress of work, some work spilling out of on the job hours (i.e.
                                            computer
                                            programming, most white collar jobs)
                                        </li>
                                        <li> Chronic stress with high stakes and industries that are challenging (i.e
                                            high
                                            school
                                            teacher, most health care jobs)
                                        </li>
                                        <li> extremely stressful with very difficult day to day circumstances, might
                                            even be
                                            traumatizing
                                            (i.e. intense health care like ER nurse, surgeon)
                                        </li>
                                    </ol>
                                </p>
                            </div>
                            <div className={"columns3"}>
                                <p className={"indexKeyTitle"}>
                                    Hours worked / Demand
                                </p>

                                <p className={"indexKeyBody"}>
                                    <ol className={"footNotesList"}>
                                        <li> You can work whenever you want and it's usually not demanding (We didn't
                                            code any
                                            of
                                            these standard jobs a 1. The gig economy and newer jobs that aren't in
                                            traditional
                                            lists
                                            would be a 1 and will be captured in the final report).
                                        </li>
                                        <li> Flexible times to work/choices, normal demands (like writers and post
                                            secondary
                                            staff)
                                        </li>
                                        <li> Normal work hours/more set times of having to be avilable (i.e. most
                                            jobs)
                                        </li>
                                        <li> Often required to work more than a normal 40 hour work week</li>
                                        <li> Tends to work all the time/on call/crazy weird hours like restaurant
                                            manager or CEO
                                        </li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div>

                            <div className={"columns3"}>
                                <p className={"indexKeyTitle"}>
                                    Safety / Physical Demand
                                </p>
                                <p className={"indexKeyBody"}>
                                    <ol className={"footNotesList"}>
                                        <li>No safety issues or physicality required (i.e. any desk/computer job)</li>
                                        <li>Not dangerous but might need to have some physical ability (i.e.
                                            waitress/retail)
                                        </li>
                                        <li> Some safety risk, having to lift things (i.e. warehouse, delivery driver,
                                            some
                                            manufacturing)
                                        </li>
                                        <li> More physically demanding, some safety risk, but most people can do it
                                            (i.e. more
                                            difficult manufacturing like welding/construction jobs, police, corrections)
                                        </li>
                                        <li> Extremely physically demanding/dangerous (i.e. climbing utility poles or
                                            cutting
                                            down
                                            trees or wind turbines, mining, fire fighting, some construction)
                                        </li>
                                    </ol>
                                </p>
                            </div>

                            <div className={"columns3"}>
                                <p className={"indexKeyTitle floatLeft"}>
                                    Pay
                                </p>
                                <p className={"indexKeyBody"}>
                                    column is calculated from the My Colorado Journey Spreadsheet using quintiles (see
                                    quintile
                                    cut-offs below)
                                </p>
                                <div className={"clearFloat"}></div>
                                <p className={"indexKeyTitle floatLeft"}>
                                    Salary Cutoffs
                                </p>
                                <p className={"indexKeyBody"}>
                                    Calculated by Quintiles in dataset
                                </p>
                                <ul className={"footNotesList clearFloat"}>
                                    <li>5 = under $17.53 per hour</li>
                                    <li>4 = between $22.09 and $17.54 per hour</li>
                                    <li>3 = between $28.13 and $22.10 per hour</li>
                                    <li>2 = between $38.04 and $28.14 per hour</li>
                                    <li>1 = over $38.04 per hour (highest occupation in the data set averages
                                        $125.53 per hour)
                                    </li>
                                </ul>
                            </div>
                            <div className={"columns3"}>
                                <p className={"indexKeyTitle floatLeft"}>
                                    Education Needed<br/><br/><br/>
                                </p>
                                <p className={"indexKeyBody"}>
                                    Column is from the BLS data and a combination of education and experience
                                    needed for each job. See formula below:
                                </p>
                                <div>
                                    <div className={"columns-55"}>
                                        <p className={"indexKeyTitle"}>
                                            Education Level Key:
                                        </p>
                                        <p className={"indexKeyBody"}>
                                            <ul className={"footNotesList"}>
                                                <li>Doctoral or professional degree = 5</li>
                                                <li>Master's degree = 4.2</li>
                                                <li>Bachelor's degree = 3.8</li>
                                                <li>Associate's degree = 3.2</li>
                                                <li>Some college, no degree = 3.2</li>
                                                <li>Postsecondary nondegree award = 2.8</li>
                                                <li>High school diploma or equivalent = 1.5</li>
                                                <li>No formal educational credential = 1</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className={"columns-40"}>
                                        <p className={"indexKeyTitle"}>
                                            Experience Key:
                                        </p>
                                        <p className={"indexKeyBody"}>
                                            <ul className={"footNotesList"}>
                                                <li>5 years or more = 5</li>
                                                <li>Less than 5 years = 3</li>
                                                <li>None = 1</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default IndexKeyFootnotes;

import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {Line} from 'react-chartjs-2';

import axiosClient from '../../repo/backend'

ChartJS.register(...registerables);

class LatinxLine extends Component {
    constructor() {
        super();
        this.state = {
            barData: [],
            barOptions: []
        };

        this.lbl = [];
        this.values = [];
    }

    componentDidMount() {
        this.getChartData();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.countyName !== prevProps.countyName) {
            this.getChartData();
        }
    }

    getChartData() {
        const url = "/latinx"
            + (this.props.countyName === "Colorado State"
                ? ""
                : "/county/" + this.props.countyName);
        axiosClient.get(url).then(resLatinx => {

            this.lbl = [];
            this.values = [];

            resLatinx.data.forEach(element => {
                if (this.lbl.indexOf(element["year"]) === -1) {
                    this.lbl.push(element["year"]);
                }
                if (typeof this.values[element["race"]] === "undefined" ) {
                    this.values[element["race"]] = [];
                }
                this.values[element["race"]].push(parseInt(element["amount"]));
            });

            this.setState({
                barData: {
                    labels: this.lbl,
                    datasets: [
                        {
                            label: 'Hispanic',
                            data: this.values['Hispanic'],
                            backgroundColor: '#82D0ED',
                            borderColor: '#82D0ED',
                            borderWidth: 1,
                        },
                        {
                            label: 'White',
                            data: this.values['White'],
                            backgroundColor: '#87C13F',
                            borderColor: '#87C13F',
                            borderWidth: 1,
                        },
                        {
                            label: 'Asian',
                            data: this.values['Asian'],
                            backgroundColor: '#E70087',
                            borderColor: '#E70087',
                            borderWidth: 1,
                        },
                        {
                            label: 'American Indian',
                            data: this.values['American Indian'],
                            backgroundColor: '#652D8C',
                            borderColor: '#652D8C',
                            borderWidth: 1,
                        },
                        {
                            label: 'Black',
                            data: this.values['Black'],
                            backgroundColor: '#E81C24',
                            borderColor: '#E81C24',
                            borderWidth: 1,
                        }
                    ],
                },
                barOptions: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                title: (context) => {
                                    return this.lbl[context[0].dataIndex];
                                },
                                label: (context) => {
                                    return context.dataset.label + ': ' + context.formattedValue;
                                },
                            }
                        }
                    },
                }
            });
        });
        this.setState({
            barData: {
                labels: this.lbl,
                datasets: []
            },
        });
    }

    render() {
        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    Population forecast in {(
                    ("all" === this.props.countyName)
                        ? "Colorado"
                        : this.props.countyName
                )}
                </div>

                {Object.keys(this.state.barData).length > 0 &&
                    <Line id={"industryPostingsHiresPercent"} data={this.state.barData}
                          options={this.state.barOptions}></Line>
                }
            </div>
        );
    }

}

export default LatinxLine;

import React, {Component} from "react";
import TopOccupationsTable from "./top50/TopOccupationsTable";
import TopQualificationsTable from "./top50/TopQualificationsTable";
import TopCommonTable from "./top50/TopCommonTable";
import TopSpecializedTable from "./top50/TopSpecializedTable";
import TopSoftwareTable from "./top50/TopSoftwareTable";
import TopCompaniesTable from "./top50/TopCompaniesTable";

import "./Top50.css"

class Top50 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            top50: "occupations"
        }
        this.handleTop50.bind(this);
    }

    handleTop50(top50) {
        this.setState({
            top50: top50
        })
    }

    render() {

        return (
            <div>
                <div>
                    <div className={"alignCenter"}>
                        <button disabled={this.state.top50 === "occupations"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "occupations" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "occupations")}>Occupations
                        </button>
                        <button disabled={this.state.top50 === "companies"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "companies" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "companies")}>Companies
                        </button>
                        <button disabled={this.state.top50 === "qualifications"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "qualifications" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "qualifications")}>Qualifications
                        </button>
                        <button disabled={this.state.top50 === "specialized"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "specialized" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "specialized")}>Specialized Skills
                        </button>
                        <button disabled={this.state.top50 === "common"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "common" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "common")}>Common Skills
                        </button>
                        <button disabled={this.state.top50 === "software"}
                                className={
                                    ("buttonTop50" + (this.state.top50 === "software" ? " buttonTop50Selected" : ""))
                                }
                                onClick={this.handleTop50.bind(this, "software")}>Software Skills
                        </button>
                    </div>

                    <div className={"component alignCenter"}>
                        {this.state.top50 === "occupations" &&
                            <TopOccupationsTable countyName={this.props.countyName}
                                                 industryName={this.props.industryName}/>
                        }

                        {this.state.top50 === "qualifications" &&
                            <TopQualificationsTable countyName={this.props.countyName}
                                                    industryName={this.props.industryName}/>
                        }
                        {this.state.top50 === "common" &&
                            <TopCommonTable countyName={this.props.countyName}
                                            industryName={this.props.industryName}/>
                        }
                        {this.state.top50 === "specialized" &&
                            <TopSpecializedTable countyName={this.props.countyName}
                                                 industryName={this.props.industryName}/>
                        }
                        {this.state.top50 === "software" &&
                            <TopSoftwareTable countyName={this.props.countyName}
                                              industryName={this.props.industryName}/>
                        }
                        {this.state.top50 === "companies" &&
                            <TopCompaniesTable countyName={this.props.countyName}
                                               industryName={this.props.industryName}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Top50;

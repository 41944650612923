import React, {useEffect} from 'react';
import './vimeoDemoVideo.css';

function VimeoModal(props) {

    useEffect(() => {
        const handleKeydown = (e) => {
            if (e.key === 'Escape' && props.showDemoVideo) {
                props.hide();
            }
        };

        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [props.showDemoVideo]);

    return (
        <>
            {props.showDemoVideo && (
                <div className="modal-overlay"
                     onClick={() => props.hide()}>
                    <span className="close-btn"
                          onClick={() => props.hide()}>X</span>
                    <div className="modal-content"
                         onClick={e => {
                             e.stopPropagation();
                             props.hide()
                         }}>
                        <iframe src="https://player.vimeo.com/video/770830380?autoplay=1"
                                width="640"
                                height="360"
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen
                                title="Vimeo Video"></iframe>
                    </div>
                </div>
            )}
        </>
    );
}

export default VimeoModal;

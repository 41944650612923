import React from 'react';

const topOccupationsColumns = () => {
    return [
        {
            key: "row_num",
            label:
                <div className={"top50cell top50cellRownum top50cellLabel"}>#</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellRownum"}>
                    {row.row_num}
                </div>;
            }
        },
        {
            key: "company",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"}>Company</div>,
            cell: (row, columnKey) => {
                const textMaxLength = (window.outerWidth < 800) ? 22 : 45;

                return <div className={"top50cell top50cellCompany"}>
                    {row.company.substring(0, textMaxLength) + (row.company.length > textMaxLength ? "..." : "")}
                </div>;
            }
        },
        {
            key: "total_postings", label: "Total Postings", cell: (row, columnKey) => {
                return <div className={"top50cell top50cellCompanyTotalPostings"}>
                    {row.total_postings}
                </div>;
            }
        },
        {
            key: "unique_postings",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"} style={{paddingLeft: "40px"}}>Unique</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellCompanyUniquePostings"}>
                    {row.unique_postings}
                </div>;
            }
        },
    ];
}

export default topOccupationsColumns();

import React, {Component} from "react";
import axiosClient from "../../repo/backend";

export default class CountySelector extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            countiesDropDown: []
        }
        this.onChange.bind(this)
    }

    componentDidMount() {
        this.getCounties();
    }

    getCounties() {
        axiosClient.get("/counties").then(resCounties => {

            let options = [];

            options.push(
                <option key={"Colorado State"} value={"Colorado State"}>Colorado State</option>
            );

            resCounties.data.forEach(element => {
                options.push(
                    <option key={element.county_fips} value={element.county_name}>{element.county_name}</option>
                );
            });

            this.setState({
                countiesDropDown: options
            });
        });
    }

    onChange(e) {
        this.props.setCountyName(e.target.value);
    }

    render() {

        return (
            <select
                className={this.props.className}
                onChange={this.onChange.bind(this)}
                value={this.props.countyName}
            >
                {this.state.countiesDropDown}
            </select>
        )
    }

}

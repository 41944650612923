import React, {Component} from "react";
import {Doughnut} from 'react-chartjs-2';
import "./VOCPieChart.css"
import axiosClient from "../../repo/backend";

class VOCPieChart extends Component {

    constructor(props) {
        super(props);
        this.apiCall.bind(this);
        this.state = {
            data: []
        }
        this.lbl = [];
        this.values1 = [];
        this.values2 = [];
        this.labels = new Map();
        this.labels.set("0", "Never/Rarely");
        this.labels.set("1", "Sometimes");
        this.labels.set("2", "Always/Mostly");

    }
    componentDidMount() {
        this.apiCall();
    }

    apiCall() {
        const topOccupationsUrl = this.props.url;
        axiosClient.get(topOccupationsUrl).then(res => {

            this.lbl = [];
            this.values1 = [];
            this.values2 = [];

            res.data.forEach(element => {
                const caption = element["caption"];
                if (["0", "1", "2"].includes(caption)) {
                    this.lbl.push(this.labels.get(caption));
                } else {
                    this.lbl.push(caption);
                }
                this.values1.push(element["amount"]);
                this.values2.push(element["percent"]);
            });

            this.setState({
                data: res.data
            });
        });
    }

    render() {
        const data = {
            labels: this.lbl, datasets: [{
                label: '# of Votes',
                data: this.values2,
                backgroundColor: [
                    '#EC5A28', '#82D0ED', '#87C13F',
                    '#FFED00', '#E70087', '#E81C24',
                ],
            }],
        };

        return (<div>
            <div className={"VOCPieChart"}>
                <div className={"VOCPieChartTitle"}>{this.props.title}</div>
                <div className={"VOCPieChartCenter"}>{this.props.centerCaption}</div>
                <Doughnut data={data}/>
            </div>
        </div>)
    }

}

export default VOCPieChart

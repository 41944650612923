import React from 'react';

const topOccupationsColumns = () => {
    return [
        {
            key: "row_num",
            label:
                <div className={"top50cell top50cellRownum top50cellLabel"}>#</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellRownum"}>
                    {row.row_num}
                </div>;
            }
        },
        {
            key: "skill",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"}>Specialized Skill</div>,
            cell: (row, columnKey) => {
                const textMaxLength = (window.outerWidth < 800) ? 24 : 50;

                return <div className={"top50cell top50cellSpecialized"}>
                    {row.skill.substring(0, textMaxLength)}
                </div>;
            }
        },
        {
            key: "postings",
            label:
                <div className={"top50cell top50cellSpecializedTotalPostings top50cellLabel"}>Postings</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellSpecializedTotalPostings"}>
                    {row.postings}
                </div>;
            }
        },
        {
            key: "profiles",
            label:
                <div className={"top50cell top50cellSpecializedTotalProfiles top50cellLabel"}>Profiles</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellSpecializedTotalProfiles"}>
                    {row.profiles}
                </div>;
            }
        },
    ];
}

export default topOccupationsColumns();

import React from 'react';

const topOccupationsColumns = () => {
    return [
        {
            key: "row_num",
            label:
                <div className={"top50cell top50cellRownum top50cellLabel"}>#</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellRownum"}>
                    {row.row_num}
                </div>;
            }
        },
        {
            key: "skill",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"}>Software Skill</div>,
            cell: (row, columnKey) => {
                const textMaxLength = (window.outerWidth < 800) ? 24 : 55;
                return <div className={"top50cell top50cellSoftware"}>
                                        <span title={(row.skill.length > textMaxLength ? row.skill : "")}>
                        {row.skill.substring(0, textMaxLength) + (row.skill.length > textMaxLength ? "..." : "")}
                    </span>
                </div>;
            }
        },
        {
            key: "postings",
            label:
                <div className={"top50cell top50cellSoftwareTotalPostings top50cellLabel"}>Postings</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellSoftwareTotalPostings"}>
                    {row.postings}
                </div>;
            }
        },
        {
            key: "profiles",
            label:
                <div className={"top50cell top50cellSoftwareTotalProfiles top50cellLabel"}>Profiles</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellSoftwareTotalProfiles"}>
                    {row.profiles}
                </div>;
            }
        },
    ];
}

export default topOccupationsColumns();

import React, {Component} from 'react';
import JsonTable from "ts-react-json-table";
import topOccupationsColumns from "./topOccupationsColumns";
import axiosClient from '../../../repo/backend'

class TopOccupationsTable extends Component {
    constructor(props) {
        super(props);
        this.apiCall.bind(this);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.apiCall();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.countyName !== prevProps.countyName
            || this.props.industryName !== prevProps.industryName) {
            this.apiCall();
        }
    }

    apiCall() {
        const topOccupationsUrl = "/topOccupations"
            + "/county/" + this.props.countyName
            + (
                this.props.industryName === "All Industries"
                    ? ""
                    : "/industry/" + this.props.industryName
            );
        axiosClient.get(topOccupationsUrl).then(resTopOccupations => {
            this.setState({
                data: resTopOccupations.data
            });
        });
    }

    render() {
        return (
            <div className={"alignCenter"}>
                <div className={"title top50Title"}>
                    <div className={"top50SectionTitle"}>
                        Top Occupations in {this.props.countyName}
                    </div>
                    <div className={"top50SubSectionTitle"}>
                        {"all" === this.props.industryName ? "All Industries" : this.props.industryName}
                    </div>
                </div>
                {Object.keys(this.state.data).length > 0 ?
                    <div className={"top50Table"}>
                        <JsonTable rows={this.state.data}
                                   columns={topOccupationsColumns}/>
                        <div className={"top50FootNote"}>
                            *Occupation
                            list is shortened from the top 50 to reflect only
                            occupation titles (SOC) that appear in selected industry's data.
                        </div>
                    </div>
                    : <div>
                        <div className={"noResults"}>No Results</div>
                    </div>
                }
            </div>
        );
    }
}

export default TopOccupationsTable;

import {Component} from "react";

export default class GraphFootnotes extends Component {

    render() {
        return (
            <div className={"component alignCenter"}>
                <div className={"title sectionTitle"}>
                    Effort Index, Gap Intensity by Industry, for Selected County
                </div>
                <div className={"footNotesBody"}>
                    <p className={"indexKeyTitle alignLeft"}>
                        Effort Index:
                    </p>
                    <p className={"indexKeyBody alignLeft"}>
                        We developed this index as an easy way to quickly discern how difficult
                        it is to fill positions by industry and within different counties. The value displayed is the
                        ratio of the total number of job postings divided by the number of unique jobs posted. In other
                        words, if there are 4 unique jobs that are posted, and a total of 12 postings, then on average
                        each job had to be posted 3 times. The “Effort Index” in this case would be “3”, i.e., 12
                        total postings divided by 4 unique postings.
                    </p>
                    <p className={"indexKeyTitle alignLeft"}>
                        Gap intensity:
                    </p>
                    <p className={"indexKeyBody alignLeft"}>
                        Gap Intensity modifies the Effort Index based upon the ratio between
                        the total number of jobs and the unique postings in an industry. If there are a lot of posting
                        in a particular industry, for example, the Gap Intensity will be greater. But if the industry
                        only has a few postings compared to the number of existing jobs, the Gap Intensity will be
                        lower.
                        In other words, the Gap Intensity is a measure of the relative magnitude of the total hiring
                        challenge.
                    </p>
                    <p className={"indexKeyTitle floatLeft"}>
                        Notes:
                        <br/><br/>
                    </p>
                    <div>
                        <ul className={"footNotesList"}>
                            <li>Since both the Effort Index and the Gap Intensity are ratios, none of them
                                shows the absolute number of jobs or postings. This graph is intended as "heat map",
                                an
                                indicator of where we probably could spend some more time zeroing in on the absolute
                                numbers for
                                that county and industry. Other graphs will have that information.
                            </li>
                            <li>Missing industry data (mostly in small counties) is due to suppressed data from the
                                BLS
                                or insufficient reporting from EMSI (i.e. that county/industry doesn't use online
                                job
                                postings to hire positions).
                            </li>
                            <li>The added postings data (total and unique) comes from each individual county.
                                There are slight differences between that and the total amounts informed for the
                                whole
                                state.
                            </li>
                        </ul>
                    </div>
                    <div className={"clearFloat"}></div>
                </div>
            </div>
        )
    }
}


import React from "react";
import {HashRouter, Redirect, Route, Switch,} from "react-router-dom";
import ReactGA from "react-ga4";

import Graphs from "./pages/Graphs"
import Tables from "./pages/Tables";
import PieCharts from "./pages/PieCharts";

import "./App.css"

ReactGA.initialize("G-RTP9P3N1SG");
console.log("ReactGA initialized");

const App = () => {
    return (
        <div>
            <HashRouter>
                <Switch>
                    <Route exact path="/tables" component={Tables}/>
                    <Route exact path="/graphs" component={Graphs}/>
                    <Route exact path="/piecharts" component={PieCharts}/>
                    <Redirect to="/graphs"/>
                </Switch>
            </HashRouter>
        </div>
    )
}

export default App

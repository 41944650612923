import React, {Component} from "react";
import {Bar} from 'react-chartjs-2';
import "./VOCPieChart.css"
import axiosClient from "../../repo/backend";

class VOCPieChart extends Component {

    constructor(props) {
        super(props);
        this.apiCall.bind(this);
        this.state = {
            data: []
        }
        this.lbl = [];
        this.values1 = [];
        this.values2 = [];
        this.labels = new Map();
        this.labels.set("0", "Never/Rarely");
        this.labels.set("1", "Sometimes");
        this.labels.set("2", "Always/Mostly");

    }

    componentDidMount() {
        this.apiCall();
    }

    apiCall() {
        const topOccupationsUrl = this.props.url;
        axiosClient.get(topOccupationsUrl).then(res => {

            this.lbl = [];
            this.values1 = [];
            this.values2 = [];

            res.data.forEach(element => {
                const caption = this.labels.get(element["caption"]);
                if (this.lbl.indexOf(caption) === -1) {
                    this.lbl.push(caption);
                }
                if (element["origin"] === "pi") {
                    this.values1.push(element["percent"]);
                }
                if (element["origin"] === "pw") {
                    this.values2.push(element["percent"]);
                }
            });

            this.setState({
                data: res.data
            });

        });
    }

    render() {
        const data = {
            labels: this.lbl,
            datasets: [
                {
                    label: 'Available Jobs %',
                    data: this.values1,
                    backgroundColor: [
                        '#EC5A28',
                    ],
                },
                {
                    label: 'Job Preference %',
                    data: this.values2,
                    backgroundColor: [
                        '#82D0ED',
                    ],
                },
            ],
        };

        return (<div>
            <div className={"VOCPieChart"}>
                <div className={"VOCPieChartTitle"}>{this.props.title}</div>
                <div className={"VOCPieChartCenter"}>{this.props.centerCaption}</div>
                <Bar data={data}/>
            </div>
        </div>)
    }

}

export default VOCPieChart

const ZoomAdjuster = () => {
    let mag;
    if (window.outerWidth < 1024) {
        mag = Math.trunc(window.outerWidth / 1024 * 100);
    } else {
        mag = 100;
    }
    document.body.style.zoom = "" + mag + "%";
}

export default ZoomAdjuster;

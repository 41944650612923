import React, {Component} from "react";

class FlagKeyFootnotes extends Component {

    render() {
        return (
            <div>
                {this.props.showTableFootnotes && <div>
                    <div className={"clearFloat tableFootNotes"}>
                        <div>
                            <div className={"columns3"} style={{paddingTop: "40px"}}>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        Opportunity Jobs Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Overall Challenge Index Score &lt;= 2, and</li>
                                        <li>Change from 2021 to 2031 > 10%, and</li>
                                        <li>Total number of Jobs in 2021 > 1000 and</li>
                                        <li>Pay &lt;= 3 (which is $22.10 or more)</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        Challenging Jobs Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Change from 2021 to 2031 > 15% and</li>
                                        <li>Total number of Jobs in 2021 > 1000 and</li>
                                        <li>Overall Challenge Index Score >= 3</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"columns3"}>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        High Need, High-Wage Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Change from 2021 to 2031 > 25 and</li>
                                        <li>Total number of Jobs in 2021 > 1000 and</li>
                                        <li>Pay &lt;= 3 (which is $22.10 or more)</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        High Need, Low-Wage Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Change from 2021 to 2031 > 25 and</li>
                                        <li>Total number of Jobs in 2021 > 1000 and</li>
                                        <li>Pay >= 4 (which is $22.09 or less)</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        Opportunity Classes Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Available ETPL Classes > 0 and</li>
                                        <li>People per Class &lt;= 10 (jobs in 2031 - jobs in 2021) / available classes)
                                        and</li>
                                        <li>Change from 2021 to 2031 > 20%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"columns3"}>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        Missing Classes Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Available ETPL Classes = 0 and</li>
                                        <li>Total number of Job Growth from 2021 to 2031 > 1,000 and</li>
                                        <li>Education Needed > 1 (we did not include jobs on this list that have no
                                        required education, however, this may be important to look at as many of these
                                        jobs still need some sort of assistance to enter, especially for the population
                                        willing to work those jobs)</li>
                                        <li>OR</li>
                                        <li>Available ETPL Classes > 0 and</li>
                                        <li>People per Class > 300 (jobs in 2031 - jobs in 2021) / available classes)</li>
                                    </ul>
                                </div>
                                <div>
                                    <p className={"indexKeyTitle"}>
                                        Insufficient Completions for Projected Growth Criteria
                                    </p>
                                    <ul className={"footNotesList"}>
                                        <li>Percent of completions to openings is >0 and &lt;= 100 and</li>
                                        <li>Change from 2021 to 2031 > 15%</li>
                                        <li>Education Needed > 2 (we did not include jobs on this list that do not need
                                        any formal credentials)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"columns3"}>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default FlagKeyFootnotes;

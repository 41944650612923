import React from 'react';

const topQualificationsColumns = () => {
    return [
        {
            key: "row_num",
            label:
                <div className={"top50cell top50cellRownum top50cellLabel"}>#</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellRownum"}>
                    {row.row_num}
                </div>;
            }
        },
        {
            key: "qualification",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"}>Top Qualifications</div>,
            cell: (row, columnKey) => {
                const textMaxLength = (window.outerWidth < 800) ? 32 : 64;

                return <div className={"top50cell top50cellQualifications"}>
                    <span title={(row.qualification.length > textMaxLength ? row.qualification : "")}>
                        {row.qualification.substring(0, textMaxLength) + (row.qualification.length > textMaxLength ? "..." : "")}
                    </span>
                </div>;
            }
        },
        {
            key: "postings_with_qualification",
            label:
                <div className={"top50cell top50cellOccupation top50cellLabel"} style={{paddingLeft: "20px"}}>Postings</div>,
            cell: (row, columnKey) => {
                return <div className={"top50cell top50cellQualificationsUniquePostings"}>
                    {row.postings_with_qualification}
                </div>;
            }
        },
    ];
}

export default topQualificationsColumns();

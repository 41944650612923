import React, {Component} from "react";
import DespGapBar from "./graphs/DespGapBar";
import JobsPostingsMultitype from "./graphs/JobsPostingsMultitype";
import GapByCountyLine from "./graphs/GapByCountyLine";
import {Link} from "react-router-dom";
import GraphFootnotes from "./graphs/GraphFootnotes";
import ColoradoMap from "./graphs/ColoradoMap";
import Top50 from "./graphs/Top50";
import './Graphs.css'
import WagesBar1YearAllIndustries from "./graphs/WagesBar1YearAllIndustries";

import graphsBanner from "../img/banner/graphs.png"
import LatinxLine from "./graphs/LatinxLine";
import SourcesFootnotes from "./SourcesFootnotes";

import pdf from "../pdf/CO Industry Demand by County Narrative_7-2022.pdf"
import WagesBar1IndustryAllYears from "./graphs/WagesBar1IndustryAllYears";
import IndustrySelector from "./graphs/IndustySelector";
import CountySelector from "./graphs/CountySelector";
import ZoomAdjuster from "../ZoomAdjuster";
import ReactGA from "react-ga4";
import new_clipart from "../img/clipart/new.png";
import red_play_button_clipart from "../img/clipart/redPlayButton.png";
import VimeoDemoVideo from "../video/demo/VimeoDemoVideo";

export default class Graphs extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            showInstructions: false,
            showDemoVideo: false,
            countyName: "Colorado State",
            industryName: "All Industries",
            orderBy: "desperation_index",
            topSelect: "all"
        });

        this.setCountyName.bind(this);
        this.setIndustryName.bind(this);
        this.setOrderBy.bind(this);

        this.handleInstructionsClick.bind(this);
    }

    setCountyName(countyName) {
        this.setState({
            countyName: countyName,
        });
    }

    setIndustryName(industryName) {
        this.setState({
            industryName: industryName,
        });
    }

    setOrderBy(orderBy) {
        this.setState({
            orderBy: orderBy
        });
    }

    setTopSelect(topSelect) {
        this.setState({
            topSelect: topSelect
        });
    }

    componentDidMount() {
        document.querySelector(".page").scrollIntoView();
        ReactGA.send({hitType: "pageview", page: "/graphs"});
        console.log("pageview sent");
    }

    handleInstructionsClick() {
        this.setState({
            showInstructions: !this.state.showInstructions
        });
    }

    render() {

        const NavigationButtons = (props) => {
            return (
                <div className={"navigation"}>
                    <div className={"inlineBlock"}>
                        <button className={"nav" + (this.state.showInstructions ? " navOpen" : "")}
                                onClick={this.handleInstructionsClick.bind(this)}>Instructions
                        </button>
                        <div className={"inlineBlock demoVideoDesktop"}>
                            <button className={"nav"}
                                    onClick={() => props.show()}>
                                <div>
                                    <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                                    <div style={{
                                        verticalAlign: "middle",
                                        display: "inline-block",
                                        paddingLeft: "10px"
                                    }}>
                                        <img src={red_play_button_clipart}
                                             alt={""}
                                             style={{width: "30px"}}/>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className={"inlineBlock"}
                         style={{width: "calc(100% - 1280px)"}}></div>
                    <div style={{width: "750px", display: "inline-block"}}>
                        <button className={"nav navSelected"}
                                disabled={true}>
                            <Link to="/graphs"
                                  className={"linkDecoration"}>Industry Demand</Link>
                        </button>
                        <button className={"nav"}>
                            <Link to="/tables"
                                  className={"linkDecoration"}>Occupation Demand</Link>
                        </button>
                        <button className={"nav"}>
                            <Link to="/piecharts"
                                  className={"linkDecoration"}>Voices of Colorado</Link>
                        </button>
                    </div>
                </div>
            );
        }

        const Instructions = () => {
            return (
                <div className={"alignLeft"}>
                    {this.state.showInstructions &&
                        <div className={"instructions inlineBlock"}>
                            {/*<div className={"inlineBlock"}*/}
                            {/*     style={{width: "25%", verticalAlign: "top", textAlign: "center"}}>*/}
                            {/*    <p>*/}
                            {/*        <a href={"https://vimeo.com/indigoedco/codata"}*/}
                            {/*           alt={""}*/}
                            {/*           target={"_blank"}>*/}
                            {/*            <img src={demoColoradoJobs}*/}
                            {/*                 alt={""}*/}
                            {/*                 style={{width: "80%"}}/>*/}
                            {/*            <br/>*/}
                            {/*            Demo video*/}
                            {/*        </a>*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            <div className={"inlineBlock"}>
                                <b>Instructions for the graphs:</b>
                                <ol>
                                    <li>Roll over any data point for more information.</li>
                                    <li>Click on any bar or dot to drill down from 2-digit NAICS industry information to
                                        6-digit
                                        NAICS industry information.
                                    </li>
                                    <li>Click on the white area of the graphs to revert back.</li>
                                    <li>Use the drop-downs to pick a county or industry.</li>
                                    <li>Click on the color key to remove the data point from the graph</li>
                                    <li>On the left Wages graph, select year from the dropdown.</li>
                                    <li>On the right Wages graph, select Industry from the dropdown.</li>
                                    <li>Use the Command Key (Mac) or Control Key (PC) + and - keys to scale the page to
                                        your
                                        screen size.
                                        Site not designed for small screens.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    }
                </div>
            );
        }

        const DropDowns = () => {
            return (
                <div style={{textSizeAdjust: "auto"}}>
                    <IndustrySelector className={"large industries inlineBlock"}
                                      industryName={this.state.industryName}
                                      setIndustryName={this.setIndustryName.bind(this)}/>
                    <CountySelector className={"large counties inlineBlock"}
                                    countyName={this.state.countyName}
                                    setCountyName={this.setCountyName.bind(this)}/>
                </div>
            );
        }

        ZoomAdjuster();

        return (

            <div className={"page"}>

                <img src={graphsBanner}
                     className={"title"}
                     alt={""}/>

                <NavigationButtons show={() => {
                    this.setState({
                        showDemoVideo: true
                    })
                }}/>
                <div className={"gap"}></div>
                <Instructions/>
                <VimeoDemoVideo showDemoVideo={this.state.showDemoVideo}
                                show={() => {
                                    this.setState({
                                        showDemoVideo: true
                                    })
                                }}
                                hide={() => {
                                    this.setState({
                                        showDemoVideo: false
                                    })
                                }}/>

                <div className={"alignCenter demoVideoMobile"}>
                    <button className={"nav"}
                            style={{width: "40%", backgroundColor: "white", borderRadius: "20px"}}
                            onClick={() => {
                                this.setState({showDemoVideo: true})
                            }}>
                        <div style={{zoom: "150%"}}>
                            <div className={"smallGap"}></div>
                            <div style={{verticalAlign: "middle", display: "inline-block"}}>Demo Video</div>
                            <div style={{verticalAlign: "middle", display: "inline-block", paddingLeft: "10px"}}>
                                <img src={red_play_button_clipart}
                                     alt={""}
                                     style={{width: "30px"}}/>
                            </div>
                        </div>
                    </button>
                </div>

                <div className={"gap"}></div>

                <div align={"center"}>
                    <div className={"leftSide alignCenter"}>
                        <DropDowns/>
                    </div>
                    <div className={"rightSide"}></div>
                </div>

                <div align={"center"}>
                    <div className={"leftSide"}>

                        <DespGapBar industryName={this.state.industryName}
                                    setIndustryName={this.setIndustryName.bind(this)}
                                    countyName={this.state.countyName}
                                    setCountyName={this.setCountyName.bind(this)}
                                    orderBy={this.state.orderBy}
                                    setOrderBy={this.setOrderBy.bind(this)}
                                    topSelect={this.state.topSelect}
                                    setTopSelect={this.setTopSelect.bind(this)}/>

                        <div className={"gap"}></div>

                        <div className={"alignCenter"}>
                            <DropDowns/>
                        </div>

                        <JobsPostingsMultitype industryName={this.state.industryName}
                                               setIndustryName={this.setIndustryName.bind(this)}
                                               countyName={this.state.countyName}
                                               orderBy={this.state.orderBy}
                                               topSelect={this.state.topSelect}/>

                        <div className={"gap"}></div>

                        <div className={"alignCenter"}>
                            <IndustrySelector className={"large industries80 inlineBlock"}
                                              industryName={this.state.industryName}
                                              setIndustryName={this.setIndustryName.bind(this)}/>
                        </div>
                        <GapByCountyLine industryName={this.state.industryName}
                                         setIndustryName={this.setIndustryName.bind(this)}/>
                    </div>
                    <div className={"rightSide"}>

                        <ColoradoMap countyName={this.state.countyName}
                                     setCountyName={this.setCountyName.bind(this)}/>

                        <div className={"largeGap"}></div>

                        <div className={"alignCenter"}>
                            <CountySelector className={"large inlineBlock"}
                                            countyName={this.state.countyName}
                                            setCountyName={this.setCountyName.bind(this)}/>
                        </div>

                        {/*{this.state.countyName === "Colorado State" &&*/}
                        {/*    <div className={"selectForDetails"}>*/}
                        {/*        For detailed information please select a county in the dropdown </div>*/}
                        {/*}*/}

                        <Top50 countyName={this.state.countyName}
                               industryName={this.state.industryName}/>
                        <div className={"largeGap"}></div>

                        <LatinxLine countyName={this.state.countyName}/>

                    </div>
                </div>

                <div className={"gap"}></div>

                <div align={"center"}>
                    <div className={"leftSideHalf"}></div>
                    <div className={"rightSideHalf alignCenter"}>
                        <div className={"alignCenter"}>
                            <IndustrySelector className={"large industries80 inlineBlock"}
                                              industryName={this.state.industryName}
                                              setIndustryName={this.setIndustryName.bind(this)}/>
                        </div>
                    </div>
                </div>

                <div className={"gap"}></div>

                <div align={"center"}>
                    <div className={"leftSideHalf"}>
                        <WagesBar1YearAllIndustries/>
                        <div className={"largeGap"}></div>
                    </div>
                    <div className={"rightSideHalf"}>
                        <WagesBar1IndustryAllYears industryName={this.state.industryName}/>
                    </div>
                </div>

                <div className={"gap"}></div>

                <div align={"center"}>
                    <div className={"leftSideHalf"}>
                        <GraphFootnotes/>
                    </div>
                    <div className={"rightSideHalf"}>
                        <SourcesFootnotes pdf={pdf}>
                            <li>
                                <b>Postings, buttons, and wages data are based on 2-digit and 6-digit NAICS Industry
                                    Codes from Lightcast - Burning Glass Econometric Modeling,
                                    January 1, 2023 - December 31, 2023.</b>
                                <img src={new_clipart}
                                     style={{width: "26px"}}
                                     alt={""}/>
                            </li>
                            <li>BLS Job Data - 2nd Qtr. 2022</li>
                            <li>NAICS codes are from the 2017 crosswalk to match Lightcast standards.</li>
                            <li>Total Jobs on the Industry Graphs are from the Bureau of Labor Statistics.</li>
                            <li>Latinx Projected growth data is from the CO Demographer’s office.</li>
                        </SourcesFootnotes>


                    </div>
                </div>

                <div className={"largeGap"}>&nbsp;</div>

            </div>
        )
    }

}

import React, {Component, useState} from 'react';
import axiosClient from '../../repo/backend';

import "./CareerTable.css";

import img_1 from "../../img/icons/1_Flags_Opportunity_Jobs.png";
import img_1_tiny from "../../img/icons/1_Flags_Opportunity_Jobs_tiny.png";
import img_2 from "../../img/icons/2_Flags_Critical_Challenge_Jobs.png";
import img_2_tiny from "../../img/icons/2_Flags_Critical_Challenge_Jobs_tiny.png";
import img_3 from "../../img/icons/3_Flags_High_need_Good_Wage.png";
import img_3_tiny from "../../img/icons/3_Flags_High_need_Good_Wage_tiny.png";
import img_4 from "../../img/icons/4_Flags_High_Need_Low_Wage.png";
import img_4_tiny from "../../img/icons/4_Flags_High_Need_Low_Wage_tiny.png";
import img_5 from "../../img/icons/5_Flags_Opportunity_Classes.png";
import img_5_tiny from "../../img/icons/5_Flags_Opportunity_Classes_tiny.png";
import img_6 from "../../img/icons/6_Flags_Missing_Classes.png";
import img_6_tiny from "../../img/icons/6_Flags_Missing_Classes_tiny.png";
import img_7 from "../../img/icons/7_Flags_Insufficient_Classes.png";
import img_7_tiny from "../../img/icons/7_Flags_Insufficient_Classes_tiny.png";

const JsonTable = require('ts-react-json-table');

class CareerTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableColumns: [],
            tableOptions: [],
            tableClassesBySoc: [],
            flagSelection: 0,
            selectMajorGroupValue: "29",
        };
        this.handleChange.bind(this);
        this.onClickHeader.bind(this);
        this.flipStrip.bind(this);
        this.flipStripNewLine.bind(this);
        this.handleSocClick.bind(this);
        this.backToList.bind(this);
        this.handleFlagChange.bind(this);

        this.careerTableRowColorOdd = "careerTableOddLine";
        this.careerTableRowColorEven = "careerTableEvenLine";
        this.careerTableColumnColorAsc = "carrerTableAscendingSort";
        this.careerTableColumnColorDesc = "carrerTableDescendingSort";
        this.careerTableRowColorCurrent = this.careerTableRowColorOdd;

        this.socTitle = "";

        this.lastSortedColumn = null;
        this.lastSortDirection = "asc";
        this.careerTableRowCount = 0;

        this.imgs = [
            null, img_1, img_2, img_3,
            img_4, img_5, img_6, img_7
        ];
        this.tiny_imgs = [
            null, img_1_tiny, img_2_tiny, img_3_tiny,
            img_4_tiny, img_5_tiny, img_6_tiny, img_7_tiny
        ];
    }

    componentDidMount() {
        this.getChartData(this.state.flagSelection, this.state.selectMajorGroupValue);
        setTimeout(() => document.querySelector(".careerTable > div > div").style = "", 1000);
    }

    handleFlagChange(newFlag) {
        if (newFlag === this.state.flagSelection) {
            this.setState({
                flagSelection: 0
            });
            this.getChartData(0, this.state.selectMajorGroupValue);
        } else {
            this.setState({
                flagSelection: newFlag
            });
            this.getChartData(newFlag, this.state.selectMajorGroupValue);
        }
    }

    handleChange(event) {
        let majorGroupValue = event.target.value;
        this.setState({
            selectMajorGroupValue: majorGroupValue
        })
        this.lastSortedColumn = null;
        this.lastSortDirection = "asc";
        this.careerTableRowCount = 0;
        this.getChartData(this.state.flagSelection, majorGroupValue);
    }

    getChartData(flagSelection, majorGroupValue) {
        const url = "/classesAvailable"
            + "/flagSelection/" + flagSelection
            + ((majorGroupValue !== "00") ? "/majorGroupId/" + majorGroupValue : "");

        axiosClient.get(url).then(resTableData => {
            const c = [];

            resTableData.data.forEach(e => {
                c.push({
                    "soc": e["soc"],
                    "occupation_description": e["occupation_description"],
                    "defined_classes": parseInt(e["defined_classes"]),
                    "available_classes": parseInt(e["available_classes"]),
                    "available_100pct_online": parseInt(e["available_100pct_online"]),
                    "jobs2021": parseInt(e["jobs2021"]),
                    "jobs2031": parseInt(e["jobs2031"]),
                    "change2021to2031_percent": parseInt(e["change2021to2031_percent"]),
                    "annual_openings": parseInt(e["annual_openings"]),
                    "regional_completions2020": parseInt(e["regional_completions2020"]),
                    "median_hourly_earnings": parseInt(e["median_hourly_earnings"]),
                    "work_from_home": e["work_from_home"],
                    "stress_level": e["stress_level"],
                    "pay": parseInt(e["pay"]),
                    "education_needed": parseFloat(e["education_needed"]),
                    "hours_worked_demand": e["hours_worked_demand"],
                    "safety_physical_demand": e["safety_physical_demand"],
                    "pain5_scale": parseFloat(e["pain5_scale"]),
                    "opportunity5_scale": e["opportunity5_scale"],
                    "people_per_class": parseInt(e["people_per_class"]),
                    "job_growth": parseInt(e["job_growth"]),
                    "f_opportunity_jobs": e["f_opportunity_jobs"] === "true",
                    "f_challenging_jobs": e["f_challenging_jobs"] === "true",
                    "f_high_need_great_pay_jobs": e["f_high_need_great_pay_jobs"] === "true",
                    "f_high_need_low_pay_jobs": e["f_high_need_low_pay_jobs"] === "true",
                    "f_opportunity_classes": e["f_opportunity_classes"] === "true",
                    "f_missing_classes": e["f_missing_classes2"] === "true",
                    "f_insufficient_classes": e["f_insufficient_classes2"] === "true",
                    "rjratio": Math.round(parseInt(e["regional_completions2020"]) / parseInt(e["annual_openings"]) * 100),
                    "tier": e["tier"]
                });
            });

            this.setState({
                tableData: c
            });
        });
        this.setState({
            // tableData: [],
            tableClassesBySoc: []
        });
    }

    handleSocClick(soc, description) {
        this.getClassesBySoc(soc, soc + " " + description);
    }

    getClassesBySoc(soc, description) {
        const url = "/classesAvailable/soc/" + soc;
        axiosClient.get(url).then(resClassesBySoc => {
            const c = [];

            resClassesBySoc.data.forEach(e => {
                c.push({
                    "provider_name": e["provider_name"],
                    "general_program_name": e["general_program_name"],
                    "numberof_unitsof_timeto_complete_program": parseInt(e["numberof_unitsof_timeto_complete_program"]),
                    "unitsof_timefor_program": e["unitsof_timefor_program"],
                    "program_total_cost": parseInt(e["program_total_cost"]),
                    "learning_pace": e["learning_pace"],
                    "program_attendance_options": e["program_attendance_options"]
                });
            });

            this.setState({
                tableClassesBySoc: c
            });
            this.socTitle = description;
            this.props.shouldShowTableFootnotes(false);
        });
        this.setState({
            tableClassesBySoc: []
        });
    }

    backToList() {
        this.setState({
            tableClassesBySoc: []
        });
        this.props.shouldShowTableFootnotes(true);
    }

    flipStripNewLine() {
        this.careerTableRowCount++;
        if (this.careerTableRowCount % 2 !== 0) {
            this.careerTableRowColorCurrent =
                this.careerTableRowColorCurrent === this.careerTableRowColorOdd
                    ? this.careerTableRowColorEven
                    : this.careerTableRowColorOdd;
        }
        return true;
    }

    flipStrip(column) {
        return column === this.lastSortedColumn
            ? this.lastSortDirection === "asc"
                ? this.careerTableColumnColorAsc
                : this.careerTableColumnColorDesc
            : this.careerTableRowColorCurrent;
    }

    loadClassesColumns() {
        const classesColumns = [
            {
                key: "provider_name", label: "Provider Name", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellProvider"}>
                            {row.provider_name.substring(0, 50)}
                            {(row.provider_name.length > 50) ? "..." : ""}
                        </div>
                    );
                }
            },
            {
                key: "general_program_name", label: "General Program Name", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellProgramName"}>
                            {row.general_program_name.substring(0, 45)}
                            {(row.general_program_name.length > 45) ? "..." : ""}
                        </div>
                    );
                }
            },
            {
                key: "Duration", label: "Length", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellDuration"}>
                            {row.numberof_unitsof_timeto_complete_program}&nbsp;
                            {row.unitsof_timefor_program}
                        </div>
                    );
                }
            },
            {
                key: "program_total_cost", label: "Total Cost", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellTotalCost"}>
                            $ {row.program_total_cost}
                        </div>
                    );
                }
            },
            {
                key: "learning_pace", label: "Learning Pace", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellLearningPace"}>
                            {(row.learning_pace === "") ? "N/A" : row.learning_pace}
                        </div>
                    );
                }
            },
            {
                key: "attendance", label: "Attendance Options", cell: (row, columnKey) => {
                    return (
                        <div className={"classesCell classesCellAttendance"}>
                            {(row.program_attendance_options === "") ? "N/A" : row.program_attendance_options}
                        </div>);
                }
            },
        ];
        return classesColumns;
    }

    tinyHeader(title, src) {
        return <img src={src} className={"tinyHeader"} alt={""} title={title}/>
    }

    tinyRowIcon(title, src, i) {
        return <img src={src} className={"tiny"} alt={""} title={title}
                    onClick={this.handleFlagChange.bind(this, i)}
        />
    }

    loadTableColumns() {
        return [
            {
                key: "soc", label: "SOC Code", cell: (row, columnKey) => {
                    return this.flipStripNewLine() &&
                        <div className={"careerCell careerCellSoc "
                            + this.flipStrip(columnKey)}>
                            {row.soc}
                        </div>;
                }
            },
            {
                key: "tier",
                label: <span title={"Colorado top jobs"}>Tier</span>,
                cell: (row, columnKey) => {
                    return this.flipStrip() &&
                        <div className={"careerCell careerCellTier "
                            + this.flipStrip(columnKey)}>
                            {(row.tier === "3" ? " " : row.tier)}
                        </div>;
                }
            },
            {
                key: "occupation_description", label: "Occupation Description", cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellOcc "
                        + this.flipStrip(columnKey)}>
                        <span title={(row.occupation_description.length > 48 ? row.occupation_description : "")}>
                        {row.occupation_description.substring(0, 48) + (row.occupation_description.length > 48 ? "..." : "")}
                    </span>
                    </div>;
                }
            },
            {
                key: "f_opportunity_jobs",
                label: this.tinyHeader("Opportunity Jobs", img_1_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_opportunity_jobs &&
                            this.tinyRowIcon("Opportunity Jobs", img_1_tiny, 1)
                        }
                    </div>;
                }
            },
            {
                key: "f_challenging_jobs",
                label: this.tinyHeader("Challenging Jobs", img_2_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_challenging_jobs &&
                            this.tinyRowIcon("Challenging Jobs", img_2_tiny, 2)
                        }
                    </div>;
                }
            },
            {
                key: "f_high_need_great_pay_jobs",
                label: this.tinyHeader("High Need High-Wage Jobs", img_3_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_high_need_great_pay_jobs &&
                            this.tinyRowIcon("High Need High-Wage Jobs", img_3_tiny, 3)
                        }
                    </div>;
                }
            },
            {
                key: "f_high_need_low_pay_jobs",
                label: this.tinyHeader("High Need Low-Wage Jobs", img_4_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_high_need_low_pay_jobs &&
                            this.tinyRowIcon("High Need Low-Wage Jobs", img_4_tiny, 4)
                        }
                    </div>;
                }
            },

            {
                key: "f_opportunity_classes",
                label: this.tinyHeader("Opportunity Classes", img_5_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_opportunity_classes &&
                            this.tinyRowIcon("Opportunity Classes", img_5_tiny, 5)
                        }
                    </div>;
                }
            },
            {
                key: "f_missing_classes",
                label: this.tinyHeader("Missing Classes", img_6_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_missing_classes &&
                            this.tinyRowIcon("Missing Classes", img_6_tiny, 6)
                        }
                    </div>;
                }
            },
            {
                key: "f_insufficient_classes",
                label: this.tinyHeader("Insufficient Completions for Projected Growth", img_7_tiny),
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellFlags "
                        + this.flipStrip(columnKey)}>
                        {
                            row.f_insufficient_classes &&
                            this.tinyRowIcon("Insufficient Completions for Projected Growth", img_7_tiny, 7)
                        }
                    </div>;
                }
            },
            {
                key: "jobs2021", label: <div style={{textAlign: "center"}}>Jobs 2023</div>, cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellJobs "
                        + this.flipStrip(columnKey)}>
                        {row.jobs2021}
                    </div>;
                }
            },
            {
                key: "change2021to2031_percent", label: <div style={{textAlign: "center"}}>Change 2023 to 2028</div>, cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellChange "
                        + this.flipStrip(columnKey)}>
                        {row.change2021to2031_percent} %
                    </div>;
                }
            },
            {
                key: "regional_completions2020",
                label: <div title={"Regional Completions 2020"} style={{textAlign: "center"}}>Regional Compl. 2023</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellRegional "
                        + this.flipStrip(columnKey)}>
                        {row.regional_completions2020}
                    </div>;
                }
            },
            {
                key: "rjratio",
                label: <div title={"Regional Completions per Annual Openings"} style={{textAlign: "center"}}>Compl / Open</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellRJRatio "
                        + ((row.annual_openings === 0) ? " carrerCellNA " : "")
                        // + ((row.rjratio > 1000) ? " careerCellRJRatioGreen " : "")
                        // + ((row.rjratio < 100) ? " careerCellRJRatioRed " : "")
                        + this.flipStrip(columnKey)}>
                        {(row.annual_openings === 0) ? "N/A" : row.rjratio + " %"}
                    </div>;
                }
            },
            {
                key: "annual_openings",
                label: <div style={{textAlign: "center"}}>Annual Openings</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellAnnual "
                        + this.flipStrip(columnKey)}>
                        {row.annual_openings}
                    </div>;
                }
            },
            {
                key: "median_hourly_earnings",
                label: <div title={"Median Hourly Earnings"} style={{textAlign: "center"}}>$<br/>Yearly</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellHourly "
                        + this.flipStrip(columnKey)}>
                        {row.median_hourly_earnings}k
                    </div>;
                }
            },
            {
                key: "defined_classes",
                label: <div style={{textAlign: "center"}}>CIP Defined</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellDefined "
                        + this.flipStrip(columnKey)}>
                        {row.defined_classes}
                    </div>;
                }
            },
            {
                key: "available_classes",
                label: <div style={{textAlign: "center"}}>ETPL Available</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellAvailable "
                        + this.flipStrip(columnKey)}>
                        {(row.available_classes === 0) &&
                            <div className={"carrerCellNA"}>N/A</div>
                        }
                        {(row.available_classes !== 0) &&
                            <div className={"careerCellButton"}
                                 onClick={this.handleSocClick.bind(this, row.soc, row.occupation_description)}
                            >
                                {row.available_classes}
                            </div>
                        }
                    </div>;
                }
            },
            {
                key: "available_100pct_online",
                label: <div style={{textAlign: "center"}}>100% Online</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellOnline "
                        + this.flipStrip(columnKey)}>
                        {row.available_100pct_online}
                    </div>;
                }
            },
            {
                key: "pain5_scale",
                label: <div  title={"1 = Low Challenge   5 = High Challenge "} style={{textAlign: "center"}}>Overall Challenge Index</div>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellPain "
                        + this.flipStrip(columnKey)}>
                        <b>{row.pain5_scale}</b>
                    </div>;
                }
            },
            {
                key: "work_from_home",
                label: <span title={"Work from home - 1=low 5=high"}>WFH</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellWorkFromHome "
                        + this.flipStrip(columnKey)}>
                        {row.work_from_home}
                    </div>;
                }
            },
            {
                key: "stress_level",
                label: <span title={"Stress Level - 1=low 5=high"}>Stress</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellStress "
                        + this.flipStrip(columnKey)}>
                        {row.stress_level}
                    </div>;
                }
            },
            {
                key: "pay",
                label: <span title={"Pay -  1=high payment  5=low payment"}>Pay</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellPay "
                        + this.flipStrip(columnKey)}>
                        {row.pay}
                    </div>;
                }
            },
            {
                key: "education_needed",
                label: <span title={"Education plus Experience Needed - 1=low 5=high"}>Edu</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellEducation "
                        + this.flipStrip(columnKey)}>
                        {row.education_needed}
                    </div>;
                }
            },
            {
                key: "hours_worked_demand",
                label: <span title={"Hours worked / demand needed - 1=low 5=high"}>Hours</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellHoursWorked "
                        + this.flipStrip(columnKey)}>
                        {row.hours_worked_demand}
                    </div>;
                }
            },
            {
                key: "safety_physical_demand",
                label: <span title={"Physical demand plus safety - 1=low 5=high"}>&nbsp;Demand</span>,
                cell: (row, columnKey) => {
                    return <div className={"careerCell careerCellSafety "
                        + this.flipStrip(columnKey)}>
                        {row.safety_physical_demand}
                    </div>;
                }
            },
        ];
    }

    onClickHeader(e, column) {
        if (this.state.tableData.length === 0) {
            return;
        }
        this.careerTableRowColorCurrent = this.careerTableRowColorOdd;

        const localTableData = this.state.tableData;
        let sortDirection;
        if (column !== this.lastSortedColumn) {
            sortDirection = "desc";
        } else {
            if (this.lastSortDirection === "asc") {
                sortDirection = "desc";
            } else {
                sortDirection = "asc";
            }
        }


        if (sortDirection === "asc") {
            localTableData.sort((a, b) => {
                return a[column] > b[column] ? 1 : -1;
            });
        } else {
            localTableData.sort((a, b) => {
                return a[column] < b[column] ? 1 : -1;
            });
        }

        this.lastSortedColumn = column;
        this.lastSortDirection = sortDirection;
        this.setState({
            tableData: localTableData
        });
    }

    settings() {
        return {
            rowClass: function (current, item) {
                return current;
            }
        };
    }

    render() {

        const BigIcon = (props) => {
            const [x, setX] = useState(0);
            const [y, setY] = useState(0);
            const [visible, setVisible] = useState(false);
            return (
                <div className={"bigIcon"}
                     onClick={this.handleFlagChange.bind(this, props.number)}>
                    <div className={"instructions instructionNarrow alignCenter"}
                         style={{
                             display: (visible ? "block" : "none"),
                             position: "absolute",
                             top: y,
                             left: x,
                             zIndex: 99
                         }}
                    >
                        {props.children}
                    </div>
                    <img src={this.imgs[props.number]}
                         onMouseOver={(e) => {
                             if (!visible) {
                                 setY(e.nativeEvent.layerY + 65);
                                 setX(e.nativeEvent.layerX - 165);
                                 setVisible(true);
                             }
                         }}
                         onMouseOut={(e) => {
                             setVisible(false);
                         }}
                         className={
                             [0, props.number].includes(this.state.flagSelection)
                                 ? "showing"
                                 : "hiding"
                         }
                         alt={""}/>
                </div>

            );
        }


        const BigIcons = () => {
            return (
                <div>
                    <div>
                        <BigIcon number={1}>
                            <b>Opportunity Jobs</b> are growing (more than 10%)
                            high paying, and overall low challenge. They
                            are great jobs to encourage more people to
                            pursue and should be relatively easy to fill
                            with the right pipelines in place.
                        </BigIcon>
                        <BigIcon number={2}>
                            <b>Challenging Jobs</b> are growing (more than 15%),
                            but challenging overall. We suggest work with
                            industry to reduce challenge and provide
                            free/low cost training opportunities for job seekers.
                        </BigIcon>
                        <BigIcon number={3}>
                            <b>High Need, High-Wage Jobs</b> are growing quickly
                            (more than 25%), and provide a living wage, but
                            challenging overall. We suggest work with industry
                            to reduce challenge and provide more pathways for
                            diverse job seekers to obtain these jobs.
                        </BigIcon>
                        <BigIcon number={4}>
                            <b>High Need, Low-Wage Jobs</b> are growing quickly
                            (more than 25%), but do not typically provide
                            a living wage and are challenging overall.
                            We suggest work with industry to reduce challenge,
                            create clear pathways to higher wage roles, and
                            provide free/low cost training opportunities for
                            job seekers. Many of these jobs are critical for
                            our economy to function!
                        </BigIcon>
                        <BigIcon number={5}>
                            <b>Opportunity Classes</b> indicate ETPL programs that appear
                            to be widely available for jobs that are needed in the
                            economy. We recommend increasing interest in these
                            classes.<br/><br/>
                            <i>*Note: ETPL does not represent all training programs
                                available in the state, only those on the approved list.
                                However, based on the data we have, these classes seem to
                                be opportunities for job seekers!</i>
                        </BigIcon>
                        <BigIcon number={6}>
                            <b>Missing Classes</b> indicate jobs where there is a large number
                            of new trained workforce needed but currently zero or not
                            enough approved ETPL programs available. <br/><br/>
                            <i>*Note: ETPL does not represent all training programs available
                                in the state, only those on the approved list. See below for more
                                specific criteria.</i>
                        </BigIcon>
                        <BigIcon number={7}>
                            <b>Insufficient Completions</b> for Projected Growth indicate jobs
                            where there is a large number of new trained workforce needed
                            but not enough completions to keep up with the demand.<br/><br/>
                            <i>*Note: Completion data may not be accurate for all jobs. See
                                below for more specific criteria</i>
                        </BigIcon>
                    </div>
                </div>
            );
        }

        const columns = this.loadTableColumns.bind(this)();
        const classesColumns = this.loadClassesColumns();
        this.careerTableRowCount = 0;
        this.careerTableRowColorCurrent = this.careerTableRowColorOdd;

        return (
            <div className={"alignCenter"}>

                <BigIcons/>

                <select className={"large"}
                        onChange={this.handleChange.bind(this)}
                        value={this.state.selectMajorGroupValue}
                >
                    <option value={"00"}>All groups</option>
                    <option value={"11"}>11 - Management Occupations</option>
                    <option value={"13"}>13 - Business and Financial Operations Occupations</option>
                    <option value={"15"}>15 - Computer and Mathematical Occupations</option>
                    <option value={"17"}>17 - Architecture and Engineering Occupations</option>
                    <option value={"19"}>19 - Life, Physical, and Social Science Occupations</option>
                    <option value={"21"}>21 - Community and Social Services Occupations</option>
                    <option value={"23"}>23 - Legal Occupations</option>
                    <option value={"25"}>25 - Education, Training, and Library Occupations</option>
                    <option value={"27"}>27 - Arts, Design, Entertainment, Sports, and Media Occupations</option>
                    <option value={"29"}>29 - Healthcare Practitioners and Technical Occupations</option>
                    <option value={"31"}>31 - Healthcare Support Occupations</option>
                    <option value={"33"}>33 - Protective Service Occupations</option>
                    <option value={"35"}>35 - Food Preparation and Serving Related Occupations</option>
                    <option value={"37"}>37 - Building and Grounds Cleaning and Maintenance Occupations</option>
                    <option value={"39"}>39 - Personal Care and Service Occupations</option>
                    <option value={"41"}>41 - Sales and Related Occupations</option>
                    <option value={"43"}>43 - Office and Administrative Support Occupations</option>
                    <option value={"45"}>45 - Farming, Fishing, and Forestry Occupations</option>
                    <option value={"47"}>47 - Construction and Extraction Occupations</option>
                    <option value={"49"}>49 - Installation, Maintenance, and Repair Occupations</option>
                    <option value={"51"}>51 - Production Occupations</option>
                    <option value={"53"}>53 - Transportation and Material Moving Occupations</option>
                    {/*<option value={"55"}>55 - Military Specific Occupations</option>*/}
                </select>

                {Object.keys(this.state.tableData).length === 0 &&
                    <div>
                        <div className={"noResults"}>
                            No results
                        </div>
                    </div>
                }

                {Object.keys(this.state.tableData).length > 0 &&
                    Object.keys(this.state.tableClassesBySoc).length === 0 &&
                    <div className={"alignCenter inlineBlock width100"}>
                        <div className={"recordsShown alignCenter"}>
                            Records shown: {Object.keys(this.state.tableData).length}
                        </div>
                        <div className={"classesTableContainer"}>
                            <div className={"careerTable"}>
                                <JsonTable
                                    rows={this.state.tableData}
                                    columns={columns}
                                    onClickHeader={this.onClickHeader.bind(this)}
                                    settings={this.settings()}
                                />
                            </div>
                        </div>
                    </div>
                }

                {Object.keys(this.state.tableClassesBySoc).length > 0 &&
                    <div className={"alignCenter"}>
                        <br/>
                        <div style={{marginLeft: "30px"}}>
                            <div className={"classesTableTitle alignLeft"}>
                                {this.socTitle}
                            </div>
                            <div className={"alignLeft"}>
                                <button
                                    onClick={this.backToList.bind(this)}
                                    className={"big"}
                                >Back
                                </button>
                            </div>
                        </div>

                        <div className={"classesTableContainer"}>
                            <div className={"classesTable inlineBlock"}>
                                <br/>
                                <div className={"careerTable"}>
                                    <JsonTable
                                        rows={this.state.tableClassesBySoc}
                                        columns={classesColumns}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default CareerTable;
